export default class FaceGeometry{
    constructor(video){
        this.numFeatures = 4;
        this.video = video;
    }

    update(mesh){
        const keypoints = mesh.keypoints;
        this.curYaw = this.getFaceYaw(keypoints);
        this.curPitch = this.getFacePitch(keypoints);
        this.curRoll = this.getFaceRoll(keypoints);
        this.curFaceSize = this.getFaceSize(mesh.box);
    }

    getGeom(){
        return [this.curYaw, this.curPitch, this.curRoll, this.curFaceSize];
    }

    //Yaw is the angle in the x-z plane with the vertical axis at the origin
    //Return is in radians. Turning the head left is a positive angle, right is a negative angle, 0 is head on.
    getFaceYaw(keypoints){
        return Math.atan((keypoints[50].z - keypoints[280].z) / (keypoints[50].x - keypoints[280].x)); // Uses two cheek points
    }

    //Pitch is the angle in the z-y plane with the horizontal axis at the origin
    //Return is in radians. Turning the head up is a positive angle, down is a negative angle, 0 is head on.
    getFacePitch(keypoints){
        //Use two points on forehead because it has a z normal vector
        return Math.atan((keypoints[10].z - keypoints[168].z) / (keypoints[168].y - keypoints[10].y));
    };

    // Roll is the angle in the x-y plane (face plane)
    // returns in radians.
    getFaceRoll(keypoints){
    //    roll = Math.atan2(((keypoints[280].x-keypoints[50].x), keypoints[50].y-keypoints[280].y))
        let a = 151;
        let b = 6;
        const roll = Math.atan2(keypoints[a].x - keypoints[b].x, keypoints[b].y - keypoints[a].y);
        return roll;
    }

    getFaceSize(bb){
        return (bb.height) * (bb.width) / (this.video.videoWidth * this.video.videoHeight);
    }
}