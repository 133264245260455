
import CustomToast from "@/Components/CustomToast";
import { useEffect, useRef, useState } from "react";
import { Button, Fade } from "react-bootstrap";
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as faceMesh from '@mediapipe/face_mesh';
import Gaze from '@/libs/gaze/gaze'
import { Head, router } from '@inertiajs/react';
import axios from 'axios';
import description1 from "%/sounds/exercises/leading_eye_movement/description_1.mp3";
import exerciseNameDescription from "%/sounds/exercises/leading_eye_movement/exercise_name_description.mp3";
import exerciseHasBeenFinished from "%/sounds/components/exercise_has_been_finished.mp3";
import SparklingStar from '@/libs/sparklingStar';

export default function LeadingEyeMovement({auth, exercise}) {

  const [initialMessage, setInitialMessage] = useState(false);
  const [startExercise, setStartExercise] = useState(false);
  const [finishExercise, setFinishExercise] = useState(false);
  const [addedCameraPriviledges, setAddedCameraPriviledges] = useState(false);
  const [enableMeasurement, setEnableMeasurement] = useState(false);
  const isMounted = useRef(false);

  const offsetHeight = useRef(null);
  const offsetWidth = useRef(null);
  const fieldHeight = useRef(null);
  const fieldWidth = useRef(null);
  const screenWidthDivider = useRef(null);
  const screenHeightDivider = useRef(null);

  const currentCellWidth = useRef(null);
  const currentCellHeight = useRef(null);
  const pointer = useRef(null);
  const gazePointer = useRef(null);
  const gaze = useRef(null);
  const exerciseTimer = useRef(null);
  const measurementTimer = useRef(null);

  const video = useRef(null);
  const defaultSpeedOfPointer = useRef(0.1 * (exercise?.properties?.speed_of_pointer ?? 1));
  const currentExerciseIter = useRef(0);
  const exerciseLoopDict = useRef([]);
  const measurements = useRef({
    data: [],
    totalTime: 0
  });
  const description1Audio = useRef(new Audio(description1));
  const exerciseNameDescriptionAudio = useRef(new Audio(exerciseNameDescription));
  const exerciseHasBeenFinishedAudio = useRef(new Audio(exerciseHasBeenFinished));
  const mainView = useRef(null);
  const sparklingStarClass = useRef(null);

  const enableFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  }

  const disableFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      router.visit('/dashboard');
    }
  }

  const getDistanceBetweenPoints = (firstX, secondX, firstY, secondY) => {
    return Math.round(Math.sqrt
        ((secondX - firstX) ** 2
        + (secondY - firstY) ** 2)
    );
  }

  const detectIfGazeIsOnPointer = () => {
    const pointerRect = pointer.current.getBoundingClientRect();
    const gazePointerRect = gazePointer.current.getBoundingClientRect();
    const pointerRectRadius = Math.floor(pointerRect.width);
    const gazePointerRectRadius = Math.floor(gazePointerRect.width);

    if (getDistanceBetweenPoints(
        pointerRect.x + pointerRectRadius/2,
        gazePointerRect.x + gazePointerRectRadius/2,
        pointerRect.y + pointerRectRadius/2,
        gazePointerRect.y + gazePointerRectRadius/2
      ) < pointerRectRadius/2
    ) {
      for (let i=0; i<10; i++) {
        requestAnimationFrame(() => {
          mainView.current.append(sparklingStarClass.current.createStar(pointerRect.x + pointerRectRadius/2, pointerRect.y + pointerRectRadius/2));
        });
      }

      // pointer.current.childNodes[0].style.backgroundColor = 'green';
      return 1;
    } else {
      // pointer.current.childNodes[0].style.backgroundColor = 'red';
      return 0;
    }
  }

  const getRandomInt = (max, min = 0) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  const calculateTopPosition = (cellNumber) => {
    return offsetHeight.current + (cellNumber * fieldHeight.current) + ((fieldHeight.current - pointer.current.offsetHeight) / 2)
  };

  const calculateLeftPosition = (cellNumber) => {
    return offsetWidth.current + (cellNumber * fieldWidth.current) + ((fieldWidth.current - pointer.current.offsetWidth) / 2)
  };

  const runExercise = async () => {
    var fmesh = await faceLandmarksDetection.createDetector('MediaPipeFaceMesh', {
        runtime: 'mediapipe',
        refineLandmarks: true,
        maxFaces: 1,
        solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@${faceMesh.VERSION}`,
    });

    document.body.addEventListener('gazeLaunched', function gazeLauchedListener() {
      gazePointer.current = document.getElementById('gaze-pointer');
      setAddedCameraPriviledges(true);
      document.removeEventListener('gazeLaunched', gazeLauchedListener);
    });

    gaze.current.main();
  }

  const runCamera = async () => {
    setInitialMessage(false);
    // setInitialMessageSecond(true);

    await navigator.mediaDevices.getUserMedia({
      'audio': false,
      'video': {
        facingMode: 'user',
      }
    }).then(stream => {
      //setAddedCameraPriviledges(true);
      video.current.srcObject = stream;
      setInitialMessage(false);
      setStartExercise(true);
    });

    video.current.oncanplay = () => {
      video.current.play();
    };
  }

  useEffect(() => {

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const ratio = screenWidth / screenHeight;
    const screenInchSize = auth.user.screen_inch;
    const multiplier = screenInchSize / Math.sqrt(Math.pow(ratio, 2));
    const realScreenHeight = multiplier * 2.54;
    const realScreenWidth = multiplier * ratio * 2.54;
    const scaleFactor = 1/4;

    screenWidthDivider.current = Math.floor(realScreenWidth * scaleFactor);
    screenHeightDivider.current = Math.floor(realScreenHeight * scaleFactor);

    offsetHeight.current = Math.floor(((screenHeight / scaleFactor) / Math.floor(realScreenHeight) - (screenHeight / scaleFactor) / realScreenHeight) * (Math.floor(realScreenHeight) * scaleFactor / 2));
    offsetWidth.current = Math.floor(((screenWidth / scaleFactor) / Math.floor(realScreenWidth) - (screenWidth / scaleFactor) / realScreenWidth) * (Math.floor(realScreenWidth) * scaleFactor / 2));
    fieldHeight.current = Math.floor(screenHeight / Math.floor(realScreenHeight * scaleFactor));
    fieldWidth.current = Math.floor(screenWidth / Math.floor(realScreenWidth * scaleFactor));

    pointer.current.style.top = '10%';
    pointer.current.style.left = '50%';
    pointer.current.removeAttribute('hidden');

    setInitialMessage(true);

    localStorage.setItem('svr_y', '{"type":"Regression:RidgeRegression","algorithm":"RidgeRegression","userParameters":{"lambda":0.1},"lambda":0.1,"affine":true,"parameterGrid":{"lambda":{"type":"vector","data":[0.01,0.1,1,5,10]}},"w":{"type":"vector","data":[-0.27644395314584996,0.3811271086649924,-0.3345505453741162,-0.030918144923308903,-0.605910440074661,0.12787302052590419,0.5636825747988002,-0.1999563360910517,0.1537238258251311,-1.581893489920737,1.2904037440445164,-0.23172362803982657,-0.6575248771190613,0.5762209460422616,-0.2556585987485185,0.7861544672689187,-0.13529595917876258,0.7710480963596482,-0.08441042165902793,0.7779869625879292,-0.1515267518557074,0.7387066635210437,-0.9437771510031184,0.2003580693700558,0.5916230260988189,-0.03446650001605314]},"b":-0.3032432106043794}');
    localStorage.setItem('svr_x', '{"type":"Regression:RidgeRegression","algorithm":"RidgeRegression","userParameters":{"lambda":0.1},"lambda":0.1,"affine":true,"parameterGrid":{"lambda":{"type":"vector","data":[0.01,0.1,1,5,10]}},"w":{"type":"vector","data":[0.059821527338412187,0.02284225907263849,0.031223901757502497,0.0907757354930233,-0.09875762458546215,0.05976521109005277,0.029316780727989558,0.17241602125244587,0.045071595105855976,0.5720698336434847,-0.5561364635817649,-0.07991646546216513,0.05030780437925781,-0.12759630173069955,0.14706938098638428,0.20811857822786767,0.09562718997809254,0.18464253415406337,0.07844246964718893,-0.0694220902408175,0.07331571714150809,0.12318963410324381,0.5616870587714891,0.1277149307331468,0.11366151413843292,-0.03527512606396907]},"b":0.3611227924366404}');

    gaze.current = new Gaze();
    sparklingStarClass.current = new SparklingStar();
    mainView.current = document.querySelector('.full-view-for-exercises');
    isMounted.current = true;

  }, []);

  useEffect(() => {
    console.log(exercise);
    if (isMounted.current && addedCameraPriviledges === true) {
      const maxIter = exercise?.properties?.number_of_repetitions ?? 5;

      pointer.current.style.width = `${fieldWidth.current}px`;
      pointer.current.style.height = `${fieldHeight.current}px`;

      const startCellNumberHeight = parseInt(screenHeightDivider.current / 2);
      const startCellNumberWidth = parseInt(screenWidthDivider.current / 2);

      let totalTime = 0;

      for (let iter = 0; iter <= maxIter; iter++) {
        const cellNumberWidth = getRandomInt(screenWidthDivider.current - 1);
        const cellNumberHeight = getRandomInt(screenHeightDivider.current - 1);

        exerciseLoopDict.current.push({
          cellNumberWidth: cellNumberWidth,
          cellNumberHeight: cellNumberHeight,
          distance: getDistanceBetweenPoints(
            calculateTopPosition(exerciseLoopDict.current.length > 0 ? exerciseLoopDict.current[iter - 1].cellNumberHeight : startCellNumberHeight),
            calculateTopPosition(cellNumberHeight),
            calculateLeftPosition(exerciseLoopDict.current.length > 0 ? exerciseLoopDict.current[iter - 1].cellNumberWidth : startCellNumberWidth),
            calculateLeftPosition(cellNumberWidth)
          ),
        });
        console.log(defaultSpeedOfPointer.current);
        exerciseLoopDict.current[iter].time = exerciseLoopDict.current[iter].distance / defaultSpeedOfPointer.current;
        totalTime += exerciseLoopDict.current[iter].time;
      }

      measurements.current.totalTime = totalTime;

      // timer

      exerciseTimer.current = setTimeout(function exerciseTimerFunction() {
        //console.log(currentExerciseIter.current, exerciseLoopDict.current[currentExerciseIter.current].time);
        // console.log(exerciseLoopDict.current[currentExerciseIter.current].distance);

        pointer.current.style.transition = `all ${exerciseLoopDict.current[currentExerciseIter.current].time}ms linear`;

        //console.log(exerciseLoopDict.current[currentExerciseIter.current].distance);

        pointer.current.style.top = `${calculateTopPosition(exerciseLoopDict.current[currentExerciseIter.current].cellNumberHeight)}px`;
        pointer.current.style.left = `${calculateLeftPosition(exerciseLoopDict.current[currentExerciseIter.current].cellNumberWidth)}px`;

        //console.log(pointer.current.style.top, pointer.current.style.left);

        if (currentExerciseIter.current === maxIter) {
          setFinishExercise(true);
        } else {
          exerciseTimer.current = setTimeout(exerciseTimerFunction, exerciseLoopDict.current[currentExerciseIter.current].time + 500);
          currentExerciseIter.current++;
        }
      }, 2000);

      setTimeout(() => { setEnableMeasurement(true) }, 2000);
    }
  }, [addedCameraPriviledges]);

  useEffect(() => {
    if (isMounted.current && startExercise === true) {
      runExercise();
    }
  }, [startExercise]);

  useEffect(() => {
    if (isMounted.current && enableMeasurement === true) {
      function getMeasurements() {
        measurements.current.data.push(detectIfGazeIsOnPointer());
        measurementTimer.current = setTimeout(getMeasurements, 100);
      };

      getMeasurements();
    }
  }, [enableMeasurement]);

  useEffect(() => {
    if (isMounted.current && finishExercise === true) {
      exerciseHasBeenFinishedAudio.current.play();
      setStartExercise(false);
      setEnableMeasurement(false);
      pointer.current.setAttribute('hidden', true);

      const tracks = video.current.srcObject?.getTracks() ?? [];

      tracks.forEach((track) => {
        track.stop();
      });

      video.current.srcObject = null;
      const predicdot = document.querySelector('.predicdot');

      if (predicdot) {
        predicdot.remove();
      }

      clearTimeout(exerciseTimer.current);
      clearTimeout(measurementTimer.current);

      axios.post(location.href, {
        time_of_exercise: parseInt(measurements.current.totalTime),
        precision_of_measurement: 100,
        number_of_measurement: measurements.current.data.length,
        possitive_number_of_measurement: (measurements.current.data.filter((obj) => obj == 1)).length,
      });
    }

  }, [finishExercise]);

  useEffect(() => {
    if (isMounted.current) {
      if (initialMessage) {
        setTimeout(() => {
          exerciseNameDescriptionAudio.current.play();
        }, 500);
        exerciseNameDescriptionAudio.current.onended = function() {
          description1Audio.current.play();
        };
      } else {
        exerciseNameDescriptionAudio.current.currentTime = 0;
        exerciseNameDescriptionAudio.current.pause();
        description1Audio.current.currentTime = 0;
        description1Audio.current.pause();
      }
    }
  }, [initialMessage]);

  return (
    <>
      <Head>
        <script src="/gaze/mlweb.js"></script>
      </Head>
      <main id="animatedBackground" className="full-view-for-exercises">
        <CustomToast title="Wodzące ruchy oczu" className="pb-5" toggle={initialMessage}>
          <div className="mb-4">
            <div className="toast-text-underlined">
              <small className="text-justify">
                Podążaj wzrokiem za <b>bodźcem</b> na ekranie
              </small>
            </div>
            <div className="text-justify mt-3">Podczas ćwiczenia głowa powinna być ustawiona nieruchomo, na wprost kamery w odległości 40cm.</div>
          </div>
          <div className="toast-text-underlined">
            <small className="text-justify">
              <b>Rozpoczęcie ćwiczenia spowoduje uruchomienie trybu pełnoekranowego przeglądarki</b>
            </small>
          </div>
          <Button onClick={ async() => { await runCamera(); enableFullScreen() }} className="btn btn-dark mt-3 w-100">Rozpocznij</Button>
        </CustomToast>
        <CustomToast title="Wodzące ruchy oczu" className="pb-5" toggle={finishExercise}>
          <div className="mb-2 text-center">
            Zakończono ćwiczenie
          </div>
          <Button onClick={() => { disableFullScreen();} } className="btn btn-dark mt-3 w-100">Powrót do menu</Button>
        </CustomToast>
        <div ref={pointer} id="pointer" hidden className="pointer-box pointer-box-move-animation">
          <div className="pointer"></div>
        </div>
        <Fade in={true}>
          <div className="video-container">
            <canvas hidden id="eyecache"></canvas>
            <video ref={video} className="debug-video" id="video"></video>
          </div>
        </Fade>
      </main>
    </>
  )
}
