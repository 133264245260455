import React, { useEffect, useRef, useState } from "react";
import { Fade, Toast, ToastContainer } from "react-bootstrap";

const CustomToast = (props) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => { setShow(!show); };
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (props.toggle === true) {
        setShow(true);
      } else {
        setTimeout(() => setShow(false));
      }
    }
  }, [props.toggle]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <Fade in={show} timeout={1000}>
      <ToastContainer className={props.className} position={props?.position ?? 'middle-center'}>
        <Toast onClose={toggleShow} show={show} animation={true} style={{width: '500px'}}>
          <Toast.Header closeButton={props?.closeButton ?? false} style={{backgroundColor: 'rgb(235 235 235 / 85%)', borderBottom: '#858282 2px solid', textTransform: 'uppercase'}}>
            <h4 className="text-center w-100 m-0">{props?.title}</h4>
          </Toast.Header>
          <Toast.Body>{props.children}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fade>
  );
};
export default CustomToast;