import { useEffect, useRef, useState } from 'react';
import Modal from '@/Components/Modal';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import InputError from '@/Components/InputError';
import DecimalInput from '@/Components/DecimalInput';
import InputLabel from '@/Components/InputLabel';
import { useForm } from '@inertiajs/inertia-react';

export default function ScreenSizeModal({ className = '', showModal, onModalClose, user }) {
    const isMounted = useRef(false);
    const [screenSizeModal, setScreenSizeModal] = useState(showModal);
    const [loading, setLoading] = useState(false);

    const { data, setData, patch, errors, processing, recentlySuccessful } = useForm({
        screen_inch: user.screen_inch,
    });

    useEffect(() => {
        isMounted.current = true;
    }, []);

    const closeModal = () => {
        setScreenSizeModal(false);
        onModalClose();
    };

    const handleSubmit = () => {
        setLoading(true);
        axios.patch(`/update-screen-size`, data)
        .then(res => {
            setLoading(false);
            closeModal();
        },
        (error) => {
            errors.current = error;
            console.log(error);
            alert('Wystąpił błąd podczas zapisywania danych');
            setLoading(false);
        });
    };

    useEffect(() => {
        if (isMounted.current && showModal) {
            setScreenSizeModal(true);
        }
    }, [showModal]);

    return (
        <section className={`space-y-6 ${className}`}>
            <Modal show={screenSizeModal} onClose={closeModal} closeable={false} backdrop="static">
                <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900">
                        Wprowadź rozmiar ekranu w calach
                    </h2>
                    <div>
                        <InputLabel htmlFor="screen_inch" value="Rozmiar ekranu w calach" />

                        <DecimalInput
                            id="screen_inch"
                            className="mt-1 block w-full"
                            value={data.screen_inch}
                            onChange={(e) => setData('screen_inch', e.target.value)}
                            required
                            isFocused
                            autoComplete="screen_inch"
                            min={8}
                            max={40}
                            step={0.1}
                        />

                        <InputError className="mt-2" message={errors?.screen_inch} />
                    </div>
                    <div class="mt-2">
                        <InputLabel htmlFor="pd_value" value="Wprowadź wartość PD uzyskaną od Optometrysty" />

                        <DecimalInput
                            id="pd_value"
                            className="mt-1 block w-full"
                            value={data.pd_value}
                            onChange={(e) => setData('pd_value', e.target.value)}
                            required
                            isFocused
                            autoComplete="pd_value"
                        />

                        <InputError className="mt-2" message={errors?.pd_value} />
                    </div>
                    <div className="mt-6 flex justify-end">
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={loading}
                            loadingIndicator="Zapisywanie"
                            variant="contained"
                            className="mx-3"
                            >Zapisz
                        </LoadingButton>
                    </div>
                </div>
            </Modal>
        </section>
    );
}
