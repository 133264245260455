import { CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Card } from "react-bootstrap";
import CustomNumberInput from "./CustomNumberInput";
import { useEffect, useState } from "react";
import { useRef } from "react";

const ExerciseProperties = (props) => {
    const [exerciseValues, setExerciseValues] = useState({ ...exerciseValues, [`${props.tableName}_${props.propertiesKey}`]: props.propertiesValue });
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    useEffect(() => {
        if (isMounted.current) {
          props.setValues(exerciseValues);
        }
    }, [exerciseValues]);

    return <Grid container sx={{ mb: 1 }}>
            <Grid item xs={6} sx={{ m: 'auto' }}>
              <span>{props.title}</span>
            </Grid>
            <Grid item xs={6} sx={{ m: 'auto' }}>
              <CustomNumberInput step={props.step ?? 1} min={props.min ?? 1} max={props.max ?? 10} name={`${props.tableName}_${props.propertiesKey}`} value={exerciseValues[`${props.tableName}_${props.propertiesKey}`]}
                onChange={(e) => {setExerciseValues({ ...exerciseValues, [`${props.tableName}_${props.propertiesKey}`]: parseFloat(e.target.value) });}}>
              </CustomNumberInput>
            </Grid>
          </Grid>;
}

export default ExerciseProperties;