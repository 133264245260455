import { forwardRef, useEffect, useRef } from 'react';

export default forwardRef(function DecimalInput({ type = 'number', className = '', isFocused = false, min = 0, max = 100, step = 0.1, ...props }, ref) {
    const input = ref ? ref : useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    return (
        <input
            {...props}
            type={type}
            min={min}
            max={max}
            step={step}
            className={
                'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm ' +
                className
            }
            ref={input}
        />
    );
});
