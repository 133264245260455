import ExerciseHistoryDataTable from '@/Components/ExerciseHistoryDataTable';
import StyledBreadcrumb from '@/Components/StyledBreadcrumb';
import AdminAuthenticatedLayout from '@/Layouts/AdminAuthenticatedLayout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Breadcrumbs } from '@mui/material';
import { router } from '@inertiajs/react';
import { useState } from 'react';
import EyeMovementBarChart from '@/Components/EyeMovementBarChart';
import ChartBox from '@/Components/ChartBox';
import EyeLineChart from '@/Components/EyeLineChart';

export default function ExerciseHistory({ auth, user, exercise, columns, exerciseName, charts }) {
    const [isTester, setIsTester] = useState(() => Boolean(auth.user.roles.filter((obj) => obj.name === 'tester').length));

    return (
        <AdminAuthenticatedLayout
            user={auth.user}
            header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">
                <Breadcrumbs aria-label="breadcrumb">
                    {!isTester && <StyledBreadcrumb
                            component="a"
                            onClick={() => router.visit("/admin/dashboard")}
                            href="javascript:void(0)"
                            label="Panel"
                            icon={<HomeIcon fontSize="small" />}
                        />
                    }
                    <StyledBreadcrumb
                        component="a"
                        onClick={() => router.visit(`/admin/users`)}
                        href="javascript:void(0)"
                        label={isTester ? "Wyniki" : "Użytkownicy"}
                        icon={<PeopleAltIcon fontSize="small" />}
                    />
                    <StyledBreadcrumb
                        component="a"
                        onClick={() => router.visit(location.pathname)}
                        href="javascript:void(0)"
                        label={exerciseName}
                        icon={<VisibilityIcon fontSize="small" />}
                    />
                </Breadcrumbs>
            </h2>}
        >
            <div className="pt-12 pb-6">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <ExerciseHistoryDataTable exerciseName={exerciseName} data={exercise} user={user} exercise={exercise} columns={JSON.stringify(columns)}></ExerciseHistoryDataTable>
                    </div>
                </div>
            </div>
            {charts.map((chart) =>
                <div className="py-6">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="bg-white overflow-hidden shadow-md sm:rounded-lg">
                            <ChartBox title={chart.chartTitle}>
                                { chart.chartType === 'EyeMovementBarChart' &&
                                    <EyeMovementBarChart data={chart.chartData} yLabel={chart.chartYLabel}/>
                                }
                                { chart.chartType === 'EyeLineChart' &&
                                    <EyeLineChart data={chart.chartData} yLabel={chart.chartYLabel}/>
                                }
                            </ChartBox>
                        </div>
                    </div>
                </div>
            )}
        </AdminAuthenticatedLayout>
    );
}
