import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import { Head } from "@inertiajs/react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Rating,
    Typography,
} from "@mui/material";
import Countdown, { zeroPad } from "react-countdown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import CalibrationSettingsModal from "@/Pages/Profile/Partials/CalibrationSettingsModal";
import ScreenSizeModal from "./Profile/Partials/ScreenSizeModal";

export default function Dashboard({ auth, exercisesStages }) {
    const isFocalLength = () => {
        return auth.user.focal_length === null ? true : false;
    };

    const [expanded, setExpanded] = useState(
        exercisesStages.data[0]?.latest_unfinished_stage?.stage
    );
    const [showCalibrationModal, setShowCalibrationModal] = useState(
        isFocalLength()
    );
    const [showScreenSizeModal, setShowScreenSizeModal] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onCalibrationModalClose = () => {
        setShowCalibrationModal(false);
        setShowScreenSizeModal(true);
    };

    const onScreenSizeModalClose = () => {
        setShowScreenSizeModal(false);
    };

    return (
        <AuthenticatedLayout
            user={auth.user}
            header={
                <h2 className="container font-semibold text-xl text-gray-800 leading-tight">
                    Zestaw ćwiczeń
                </h2>
            }
        >
            <Head title="Zestaw ćwiczeń" />
            <div className="py-12">
                <div className="container max-w9xl mx-auto sm:px-6 lg:px-8">
                    {exercisesStages.data.map((exerciseStage) => (
                        <Accordion
                            expanded={expanded == exerciseStage.stage}
                            onChange={handleChange(exerciseStage.stage)}
                            className="shadow-md mb-3"
                            sx={{
                                mb: 3,
                                borderRadius: 1,
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${exerciseStage.stage}-content`}
                                id={`panel${exerciseStage.stage}-header`}
                            >
                                <Typography
                                    variant="h4"
                                    className="badge bg-dark"
                                    sx={{
                                        fontSize: "16px",
                                        p: 1,
                                        m: 0,
                                    }}
                                >
                                    Poziom {exerciseStage.stage}
                                </Typography>
                                {!exerciseStage.is_previous_stage_finished && (
                                    <Typography
                                        variant="h4"
                                        className="badge bg-dark"
                                        sx={{
                                            fontSize: "16px",
                                            p: 1,
                                            mx: 1,
                                        }}
                                    >
                                        Poziom zostanie odblokowany po
                                        ukończeniu poprzedniego poziomu
                                    </Typography>
                                )}
                            </AccordionSummary>
                            <AccordionDetails className="p-6 text-gray-900 row g-2">
                                {exerciseStage.exercises
                                    .filter((obj) => obj.is_available == true)
                                    .map((obj) => (
                                        <div className="col-sm-3" key={obj.id}>
                                            <div
                                                className={`shadow-md card m-1 ${
                                                    obj.is_enable &&
                                                    exerciseStage.is_previous_stage_finished
                                                        ? ""
                                                        : "exercise-disabled"
                                                }`}
                                            >
                                                <div className="rounded-circle bg-dark exercise-indicator-position">
                                                    <h4 className="text-center exercise-indicator-position-text">
                                                        {obj.number_of_iterations -
                                                            obj.exercises_count}
                                                    </h4>
                                                </div>
                                                <img
                                                    className="card-img-top card-image-dashboard"
                                                    src={obj.cover_photo}
                                                    alt="Card image cap"
                                                />
                                                <div className="card-body pb-1">
                                                    <h5 className="card-title card-title-dashboard">
                                                        {obj.name}
                                                    </h5>
                                                    <p className="card-text">
                                                        {obj.description}
                                                    </p>
                                                    <div className="card-text">
                                                        <b>Uśredniony wynik</b>
                                                    </div>
                                                    <Rating
                                                        name="read-only"
                                                        size="large"
                                                        value={obj.rating}
                                                        precision={0.5}
                                                        readOnly
                                                    />
                                                </div>
                                                <Countdown
                                                    renderer={(props) => (
                                                        <a
                                                            href={
                                                                obj.link_to_exercise
                                                            }
                                                            className={`btn btn-dark m-1 ${
                                                                obj.is_enable &&
                                                                exerciseStage.is_previous_stage_finished &&
                                                                props.total ===
                                                                    0
                                                                    ? ""
                                                                    : "disabled"
                                                            }`}
                                                        >
                                                            {props.total === 0
                                                                ? "Rozpocznij ćwiczenie"
                                                                : `Dostępne za ${zeroPad(
                                                                      props.hours
                                                                  )}:${zeroPad(
                                                                      props.minutes
                                                                  )}:${zeroPad(
                                                                      props.seconds
                                                                  )}`}
                                                        </a>
                                                    )}
                                                    date={
                                                        Date.now() +
                                                        obj.time_to_next_iteration *
                                                            1000
                                                    }
                                                ></Countdown>
                                            </div>
                                        </div>
                                    ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
            {isFocalLength() && (
                <CalibrationSettingsModal
                    showModal={showCalibrationModal}
                    onModalClose={onCalibrationModalClose}
                    firstRender={true}
                />
            )}
            {isFocalLength() && (
                <ScreenSizeModal
                    showModal={showScreenSizeModal}
                    onModalClose={onScreenSizeModalClose}
                    user={auth.user}
                />
            )}
        </AuthenticatedLayout>
    );
}
