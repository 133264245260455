import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Box, Button } from '@mui/material';

const ExerciseHistoryDataTable = (props) => {

  const [tableData, setTableData] = useState(() => props.data);
  const [tableDataColumns, setTableDataColumns] = useState(() => JSON.parse(props.columns));

  const handleExportRows = (rows) => {
    const doc = new jsPDF({

    });

    const tableData = rows.map(function(row, idx) {
      const data = Object.values(row.original);
      data.unshift(idx + 1);
      return data
    });

    let tableHeaders = tableDataColumns.map((c) => c.header);
    tableHeaders.unshift('#');

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    let fileName = `${props.exerciseName}_${props.user.full_name}`.split(' ').join('_').toLocaleLowerCase();
    const letters     = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
    const replacement = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];

    for (let i = 0; i < letters.length; ++i) {
      fileName = fileName.replaceAll(letters[i], replacement[i]);
    }
    doc.save(`${fileName}.pdf`);
  };

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Eksportuj wszystko
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Eksportuj wybrane
              </Button>
            </Box>
          )
        }
        columns={tableDataColumns}
        data={tableData}
        enableRowNumbers
        enableRowSelection={true}
        rowNumberMode="original"
        enableColumnOrdering
        enableStickyHeader
      />
    </>
  );
};

export default ExerciseHistoryDataTable;