import { useEffect, useRef, useState } from 'react';
import DangerButton from '@/Components/DangerButton';
import SecondaryButton from '@/Components/SecondaryButton';
import FaceDetectionEngine from "@/libs/faceDetectionEngine";
import Modal from '@/Components/Modal';
import { LoadingButton } from '@mui/lab';
import CalibrationSettingsModal from './CalibrationSettingsModal'

export default function CalibrationSettingsInfo({ className = '' }) {
    const [showModal, setShowModal] = useState(false);

    const onClose = () => {
        setShowModal(false);
    }

    return (
        <section className={`space-y-6 ${className}`}>
            <header>
                <h2 className="text-lg font-medium text-gray-900">Kalibracja odległości od kamery</h2>
                <p className="mt-1 text-sm text-gray-600">
                    Dokonaj kalibracji kamery, aby poprawnie określać Twoją odległość od ekranu.
                </p>
            </header>
            <DangerButton onClick={() => setShowModal(true)}>Kalibruj</DangerButton>
            <CalibrationSettingsModal showModal={showModal} onModalClose={onClose} />
        </section>
    );
}
