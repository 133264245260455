// import '../css/app.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';

const appName = 'Eye Solutions';

createInertiaApp({
    title: (title) => `${appName}`,
    resolve: (name) => require(`./Pages/${name}.jsx`),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});
