import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { createTheme, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  TableBody,
  Table,
  TableHead,
  Paper,
  TableRow,
  Rating,
} from '@mui/material';

import { router } from '@inertiajs/react';
import axios from 'axios';
import ExerciseSettings from './ExerciseSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const UserPrimaryDataModal = ({ showModal, onModalClose }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setErrors({});
    if (checkValidation()) {
      setLoading(true);
      axios.patch('/admin/users/primary-data', values)
        .then(res => {
          if (res.status === 200) {
            setLoading(false);
            onModalClose();
            router.reload();
          } else {
            setLoading(false);
            router.reload();
          }
        }, (error) => {
          alert('Wystąpił błąd podczas zapisywania danych');
          setLoading(false);
          router.reload();
        });

        setValues({});
    }
  };

  const checkValidation = () => {
    if (!!!values.first_name?.length) {
      setErrors({ ...errors, first_name: 'Wprowadź imię użytkownika'});
      return false;
    }
    if (!!!values.last_name?.length) {
      setErrors({ ...errors, last_name: 'Wprowadź nazwisko użytkownika'});
      return false;
    }
    if (!!!values.born_date?.length) {
      setErrors({ ...errors, born_date: 'Wprowadź datę urodzenia użytkownika'});
      return false;
    }

    return true;
  }

  return (
    <Dialog open={showModal} maxWidth='xl' sx={{ minWidth: '750px' }}>
      <DialogTitle textAlign="center"
        sx={{
          background: '#00000012',
          borderRadius: 1,
          color: '#6E6E6E',
          borderBottom: '1px solid #BBBCBD',
          p: 1,
          mb: 2
        }}>Wprowadź dane pacjenta</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                sx={{
                  gap: '1rem',
                  p: 0,
                  pt: 1,
                }}
              >
                <TextField
                  error={!!errors.first_name}
                  key="first_name"
                  label="Imię"
                  name="first_name"
                  required
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                  variant="outlined"
                  size="small"
                  helperText={errors.first_name}
                />
                <TextField
                  error={!!errors.last_name}
                  key="last_name"
                  label="Nazwisko"
                  name="last_name"
                  required
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                  variant="outlined"
                  size="small"
                  helperText={errors.last_name}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      value={typeof values.born_date === 'undefined' ? null : dayjs(values.born_date)}
                      required
                      label="Data urodzenia"
                      timezone="Europe/Warsaw"
                      openTo="year"
                      format="YYYY-MM-DD"
                      slotProps={{
                        textField: {
                          helperText: errors.born_date,
                        },
                      }}
                      onChange={(data) => setValues({ ...values, ['born_date']: dayjs(data).format('YYYY-MM-DD') })}
                      helperText={errors.born_date}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button
          onClick={() => router.post(route('logout'))}
          variant="contained"
          color="error"
          className='mx-2'
        >Wyloguj</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator="Zapisywanie"
          variant="contained"
        >Zapisz dane</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserPrimaryDataModal;