import { useEffect, useRef, useState } from "react";
import Modal from "@/Components/Modal";
import { useForm } from "@inertiajs/inertia-react";
import { Button } from "@mui/material";
import CreateNewAccountModal from "@/Components/CreateNewAccountModal";
import { LoadingButton } from "@mui/lab";
import { router, usePage } from "@inertiajs/react";
import axios from "axios";

export default function TestModeResultsModal({
    className = "",
    showModal,
    onModalClose,
    user,
}) {
    const isMounted = useRef(false);
    const [screenSizeModal, setScreenSizeModal] = useState(showModal);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    const closeModal = () => {
        setScreenSizeModal(false);
        onModalClose();
    };

    const handleApprove = () => {
        closeModal();
        setTimeout(() => {
            setCreateModalOpen(true);
        }, 200);
    };

    const handleDeny = () => {
        axios.post("/admin/users/deny-create-account").then(
            (res) => {
                if (res.status === 200) {
                    setLoading(false);
                    closeModal();
                    location.reload();
                } else {
                    setLoading(false);
                    router.reload();
                }
            },
            (error) => {
                alert("Wystąpił błąd podczas zapisywania danych");
                setLoading(false);
                router.reload();
            }
        );
    };

    useEffect(() => {
        if (isMounted.current && showModal) {
            setScreenSizeModal(true);
        }
    }, [showModal]);

    return (
        <section className={`space-y-6 ${className}`}>
            <Modal
                show={screenSizeModal}
                onClose={closeModal}
                closeable={false}
                backdrop="static"
            >
                <div className="p-6">
                    <h3 className="text-gray-900 text-center">
                        Zakwalifikowano użytkownika do ćwiczeń
                    </h3>
                    <img
                        className="veryfy-icon"
                        src="/storage/img/core/green_check.svg.png"
                        alt="Green check"
                    />
                    <div></div>
                    <div className="mt-6 flex justify-end">
                        <Button
                            onClick={handleApprove}
                            variant="contained"
                            className="mx-1"
                        >
                            Utwórz konto
                        </Button>
                        <LoadingButton
                            onClick={handleDeny}
                            loading={loading}
                            loadingIndicator="Anulowanie"
                            variant="contained"
                            color="error"
                        >
                            Anuluj
                        </LoadingButton>
                    </div>
                </div>
            </Modal>
            <CreateNewAccountModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                defaultValues={user}
            />
        </section>
    );
}
