import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { router } from '@inertiajs/react';
import axios from 'axios';
import ExerciseSettings from './ExerciseSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CreateNewAccountModal = ({ open, onClose, defaultValues }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setErrors({});
    if (checkValidation()) {
      setLoading(true);
      axios.post('/admin/users', values)
        .then(res => {
          if (res.status === 200) {
            setLoading(false);
            onClose();
            router.reload();

            if (typeof defaultValues !== 'undefined') {
              location.reload();
            }
          } else {
            setLoading(false);
            onClose();
          }
        }, (error) => {
          alert('Wystąpił błąd podczas zapisywania danych');
          setLoading(false);
          onClose();
        });

        setValues({});
    }
  };

  useState(() => {
    if (typeof defaultValues !== 'undefined') {
      setValues({first_name: defaultValues.first_name, last_name: defaultValues.last_name, born_date: defaultValues.born_date, });
    }
  }, [defaultValues])

  const updateProperties = (data) => {
    setValues({...values, ...data});
  };

  const checkValidation = () => {
    if (!!!values.first_name?.length) {
      setErrors({ ...errors, first_name: 'Wprowadź imię użytkownika'});
      return false;
    }
    if (!!!values.last_name?.length) {
      setErrors({ ...errors, last_name: 'Wprowadź nazwisko użytkownika'});
      return false;
    }
    if (!!!values.born_date?.length) {
      setErrors({ ...errors, born_date: 'Wprowadź datę urodzenia użytkownika'});
      return false;
    }
    if (!!!values.password?.length) {
      setErrors({ ...errors, password: 'Wprowadź hasło'});
      return false;
    }
    console.log(values.email?.length);
    if (!!!values.email?.length && validateEmail(values.email)) {
      setErrors({ ...errors, email: 'Wprowadź poprawny adres email'});
      return false;
    }

    return true;
  }

  return (
    <Dialog open={open} maxWidth='xl' sx={{ minWidth: '750px' }}>
      <DialogTitle textAlign="center"
        sx={{
          background: '#00000012',
          borderRadius: 1,
          color: '#6E6E6E',
          borderBottom: '1px solid #BBBCBD',
          p: 1,
          mb: 2
        }}>Utwórz nowe konto</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" align="center" gutterBottom component="div"
                className="bg-white shadow-md sm:rounded-lg"
                sx={{
                  p: 1,
                  mb: 2,
                  pb: 0,
                  color: '#6E6E6E',
                }}>Użytkownik
              </Typography>
              <Stack
                sx={{
                  gap: '1rem',
                  p: 0
                }}
              >
                <TextField
                  error={!!errors.first_name}
                  key="first_name"
                  label="Imię"
                  name="first_name"
                  defaultValue={values.first_name}
                  required
                  onChange={(e) => {
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                  }
                  variant="outlined"
                  size="small"
                  helperText={errors.first_name}
                />
                <TextField
                  error={!!errors.last_name}
                  key="last_name"
                  label="Nazwisko"
                  name="last_name"
                  defaultValue={values.last_name}
                  required
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                  variant="outlined"
                  size="small"
                  helperText={errors.last_name}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      value={typeof values.born_date === 'undefined' ? null : dayjs(values.born_date)}
                      required
                      label="Data urodzenia"
                      timezone="Europe/Warsaw"
                      openTo="year"
                      format="YYYY-MM-DD"
                      slotProps={{
                        textField: {
                          helperText: errors.born_date,
                        },
                      }}
                      onChange={(data) => setValues({ ...values, ['born_date']: dayjs(data).format('YYYY-MM-DD') })}
                      helperText={errors.born_date}
                  />
                </LocalizationProvider>
                <TextField
                  error={!!errors.email}
                  key="email"
                  label="Email"
                  name="email"
                  type="email"
                  required
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                  variant="outlined"
                  size="small"
                  helperText={errors.email}
                />
                <TextField
                  error={!!errors.password}
                  key="password"
                  label="Hasło"
                  name="password"
                  type="password"
                  required
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                  variant="outlined"
                  size="small"
                  helperText={errors.password}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" align="center" gutterBottom component="div"
                className="bg-white shadow-md sm:rounded-lg"
                sx={{
                  p: 1,
                  mb: 2,
                  pb: 0,
                  color: '#6E6E6E',
                }}>Ćwiczenia poziomu 1
              </Typography>
                <ExerciseSettings title="Sakkadowe Ruchy Oczu" name="saccadic_eye_movements" setValues={(data) => updateProperties(data)}></ExerciseSettings>
                <ExerciseSettings title="Wodzące Ruchy Oczu" name="leading_eye_movements" setValues={(data) => updateProperties(data)}></ExerciseSettings>
                <ExerciseSettings title="Ćwiczenie sprawności akomodacji" name="eye_accommodation_efficiencies" setValues={(data) => updateProperties(data)}></ExerciseSettings>
                <ExerciseSettings title="Ćwiczenie konwergencji" name="convergence_of_eyes" setValues={(data) => updateProperties(data)}></ExerciseSettings>
                <ExerciseSettings title="Ćwiczenie amplitudy akomodacji" name="amplitude_of_eye_accommodations" setValues={(data) => updateProperties(data)}></ExerciseSettings>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { setValues({}); onClose(); }}>Anuluj</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator="Zapisywanie"
          variant="contained"
        >Utwórz nowe konto</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewAccountModal;