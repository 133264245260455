import CustomToast from "@/Components/CustomToast";
import { useEffect, useRef, useState } from "react";
import { Alert, Button, Fade } from "react-bootstrap";
import FaceDetectionEngine from "@/libs/faceDetectionEngine";
import { CalculateSizeOfTheScreen } from "@/libs/screenSize";
import { router } from '@inertiajs/react';
import axios from 'axios';
import description1 from "%/sounds/exercises/eye_accommodation_efficiency/description_1.mp3";
import description2 from "%/sounds/exercises/eye_accommodation_efficiency/description_2.mp3";
import description3 from "%/sounds/exercises/eye_accommodation_efficiency/description_3.mp3";
import exerciseNameDescription from "%/sounds/exercises/eye_accommodation_efficiency/exercise_name_description.mp3";
import exerciseHasBeenFinished from "%/sounds/components/exercise_has_been_finished.mp3";
import youAreTooClose from "%/sounds/components/you_are_too_close.mp3";
import youAreTooFar from "%/sounds/components/you_are_too_far.mp3";

export default function EyeAccommodationEfficiency({auth, exercise, storyLines, constantDistanceToScreenDuringExercise}) {

  const [initialMessage, setInitialMessage] = useState(false);
  const [initialMessageSecond, setInitialMessageSecond] = useState(false);
  const [startExercise, setStartExercise] = useState(false);
  const [addedCameraPriviledges, setAddedCameraPriviledges] = useState(false);
  const [finishExercise, setFinishExercise] = useState(false);
  const isMounted = useRef(false);
  const video = useRef(null);
  const faceDetection = useRef(null);
  const distanceToScreen = useRef(null);
  const [showDistanceAlert, setShowDistanceAlert] = useState(false);
  const [showRotateFlipperInfo, setShowRotateFlipperInfo] = useState(false);
  const rotateFlipperInfo = useRef(null);
  const [startExerciseForRightEye, setStartExerciseForRightEye] = useState(false);
  const [startExerciseForLeftEye, setStartExerciseForLeftEye] = useState(false);
  const [showInfoTestSecondEye, setShowInfoTestSecondEye] = useState(false);
  const [flipperResults, setFlipperResults] = useState({
    left_eye: {
      positive_lenses: [],
      negative_lenses: []
    },
    right_eye: {
      positive_lenses: [],
      negative_lenses: []
    }
  });
  const positiveLenses = 'positive_lenses';
  const negativeLenses = 'negative_lenses';
  const distanceAlertTimeout = useRef();
  const lensesSide = useRef(positiveLenses);
  const actualTime = useRef(false);
  const oneCmIsEqual = useRef({
    width: 1,
    height: 1,
  });
  const timeOfExercise = (exercise?.properties?.duration_of_exercise ?? 10) * 1000;
  const lastStoryLine = useRef(exercise?.properties?.story_line_number ?? 0);
  const youAreTooCloseAudio = useRef(new Audio(youAreTooClose));
  const youAreTooFarAudio = useRef(new Audio(youAreTooFar));
  const description1Audio = useRef(new Audio(description1));
  const description2Audio = useRef(new Audio(description2));
  const description3Audio = useRef(new Audio(description3));
  const exerciseNameDescriptionAudio = useRef(new Audio(exerciseNameDescription));
  const exerciseHasBeenFinishedAudio = useRef(new Audio(exerciseHasBeenFinished));

  useEffect(() => {
    if (isMounted.current && startExercise === true) {
      setStartExerciseForRightEye(true);
    }
  }, [startExercise]);

  useEffect(() => {
    if (isMounted.current && startExerciseForRightEye === true) {
      video.current.addEventListener('faceTooClose', faceTooClose);
      video.current.addEventListener('faceTooFar', faceTooFar);

      actualTime.current = new Date();
      lensesSide.current = positiveLenses;

      document.addEventListener('keyup', spaceEventHandler);

      setTimeout(() => {
        setStartExerciseForRightEye(false);
        video.current.removeEventListener('faceTooClose', faceTooClose);
        video.current.removeEventListener('faceTooFar', faceTooFar);
        stopAudioMessageAboutDistance();

        document.removeEventListener('keyup', spaceEventHandler);
        setShowInfoTestSecondEye(true);
      }, timeOfExercise);
    }
  }, [startExerciseForRightEye]);

  useEffect(() => {
    if (isMounted.current && startExerciseForLeftEye === true) {
      video.current.addEventListener('faceTooClose', faceTooClose);
      video.current.addEventListener('faceTooFar', faceTooFar);

      actualTime.current = new Date();
      lensesSide.current = positiveLenses;

      document.addEventListener('keyup', spaceEventHandler);

      setTimeout(() => {
        setStartExerciseForLeftEye(false);
        video.current.removeEventListener('faceTooClose', faceTooClose);
        video.current.removeEventListener('faceTooFar', faceTooFar);
        stopAudioMessageAboutDistance();

        document.removeEventListener('keyup', spaceEventHandler);
        setStartExercise(false);
        setFinishExercise(true);
      }, timeOfExercise);
    }
  }, [startExerciseForLeftEye]);

  useEffect(() => {
    if (isMounted.current && finishExercise === true) {
      stopAudioMessageAboutDistance();
      exerciseHasBeenFinishedAudio.current.play();
      const tracks = video.current.srcObject?.getTracks() ?? [];

      tracks.forEach((track) => {
        track.stop();
      });

      video.current.srcObject = null;

      axios.post(location.href, {
        left_eye_negative_lenses: Array.from(flipperResults.left_eye.negative_lenses ?? []).toString(),
        left_eye_positive_lenses: Array.from(flipperResults.left_eye.positive_lenses ?? []).toString(),
        right_eye_negative_lenses: Array.from(flipperResults.right_eye.negative_lenses ?? []).toString(),
        right_eye_positive_lenses: Array.from(flipperResults.right_eye.positive_lenses ?? []).toString(),
        story_line_number: lastStoryLine.current ?? 1
      });
    }

  }, [finishExercise]);

  useEffect(() => {
    isMounted.current = true;

    const sizeOfTheScreen = new CalculateSizeOfTheScreen({
      scaleFactor: 1,
      screenInchSize: auth.user.screen_inch
    });
    oneCmIsEqual.current = sizeOfTheScreen.cellDimensions();
    setInitialMessage(true);
  }, []);

  const faceTooClose = (e) => {
    setShowDistanceAlert(true);
    clearTimeout(distanceAlertTimeout.current);
    distanceAlertTimeout.current = runDistanceAlertTimeout();

    youAreTooFarAudio.current.currentTime = 0;
    youAreTooFarAudio.current.pause();
    youAreTooCloseAudio.current.play();
    distanceToScreen.current.firstChild.innerHTML = `Jesteś za blisko o ${e.value} cm!`;
  }

  const faceTooFar = (e) => {
    setShowDistanceAlert(true);
    clearTimeout(distanceAlertTimeout.current);
    distanceAlertTimeout.current = runDistanceAlertTimeout();

    youAreTooCloseAudio.current.currentTime = 0;
    youAreTooCloseAudio.current.pause();
    youAreTooFarAudio.current.play();

    distanceToScreen.current.firstChild.innerHTML = `Jesteś za daleko o ${e.value} cm!`;
  }

  const stopAudioMessageAboutDistance = () => {
    youAreTooFarAudio.current.currentTime = 0;
    youAreTooCloseAudio.current.currentTime = 0;
    youAreTooFarAudio.current.pause();
    youAreTooCloseAudio.current.pause();
  }

  const runExercise = async () => {
    //distanceAlertTimeout.current = runDistanceAlertTimeout();
    await faceDetection.current.runPredictionLoop();
  };

  const runDistanceAlertTimeout = () => {
    return setTimeout(() => {
      setShowDistanceAlert(false);
    }, 300);
  }

  const spaceEventHandler = (e) => {
    if (e.code === 'Space') {
      flipperRotated();
    }
  }

  const flipperRotated = () => {
    lastStoryLine.current++;
    let eye;

    if (startExerciseForRightEye) {
      eye = 'right_eye';
    } else {
      eye = 'left_eye';
    }

    setShowRotateFlipperInfo(true);

    const time = ((new Date()).getTime() - actualTime.current.getTime()) / 1000;
    const actualLensSide = lensesSide.current;

    setFlipperResults(() => {
      const newData = flipperResults;
      newData[eye][actualLensSide].push(time);
      return newData;
    });

    actualTime.current = new Date();
    lensesSide.current = lensesSide.current === positiveLenses
      ? negativeLenses
      : positiveLenses;

    rotateFlipperInfo.current.firstChild.innerHTML = `Obrócono flipper! Korzystasz z ${lensesSide.current === 'positive_lenses' ? 'dodatnich' : 'ujemnych'} soczewek`;

    setTimeout(() => {
      setShowRotateFlipperInfo(false);
    }, 1000);
  }

  const runCamera = async () => {
    setInitialMessage(false);
    setInitialMessageSecond(true);

    await navigator.mediaDevices.getUserMedia({
      'audio': false,
      'video': {
        facingMode: 'user',
      }
    }).then(stream => {
      setAddedCameraPriviledges(true);
      video.current.srcObject = stream;
    });

    video.current.oncanplay = async (e) => {
      faceDetection.current = new FaceDetectionEngine({
        video: video.current,
        focalLength: auth.user.focal_length ?? 68,
        callback: 'keepConstantDistanceToScreen',
        constDistanceDuringExercise: constantDistanceToScreenDuringExercise
      });
      await faceDetection.current.initializePrediction();

      video.current.play();

      setTimeout(() => {
        runExercise();
      }, 200);
    };
  }

  const startBeginningSequence = async () => {
    setInitialMessageSecond(false);
    setStartExercise(true);
  };

  const enableFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  }

  const disableFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      router.visit('/dashboard');
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      if (initialMessage) {
        setTimeout(() => {
          exerciseNameDescriptionAudio.current.play();
        }, 500);
        exerciseNameDescriptionAudio.current.onended = function() {
          description1Audio.current.play();
        };
      } else {
        exerciseNameDescriptionAudio.current.currentTime = 0;
        exerciseNameDescriptionAudio.current.pause();
        description1Audio.current.currentTime = 0;
        description1Audio.current.pause();
      }
    }
  }, [initialMessage]);

  useEffect(() => {
    if (isMounted.current) {
      if (initialMessageSecond) {
        description2Audio.current.play();
      } else {
        description2Audio.current.currentTime = 0;
        description2Audio.current.pause();
      }
    }
  }, [initialMessageSecond]);

  useEffect(() => {
    if (isMounted.current) {
      if (showInfoTestSecondEye) {
        description3Audio.current.play();
      } else {
        description3Audio.current.currentTime = 0;
        description3Audio.current.pause();
      }
    }
  }, [showInfoTestSecondEye]);


  return (
    <>
      <main id="mermaid-background" className="full-view-for-exercises">
        <CustomToast title="Ćwiczenie sprawności akomodacji" className="pb-5" toggle={initialMessage}>
          <div className="mb-3">
            <div className="toast-text-underlined mb-1">
              <small className="text-justify">
                <b>Do wykonania ćwiczenia przygotuj flipper</b>
              </small>
            </div>
            <div className="text-justify">
              <b style={{ textAlign: 'center', display: 'flex', margin: '10px 0px' }}>Podczas testu zachowaj stałą odległość od kamery</b>
            </div>
            <div className="toast-text-underlined mb-1">
              <small className="text-justify">
                <b>Ćwiczenie wykonujemy jednoocznie</b>
              </small>
            </div>
            <div className="text-justify">
              Ustaw flipper tak, aby dodatnia soczewka znajdowała się przed prawym okiem. Oko lewe jest zasłonięte.
              Po środku ekranu zostaną wyświetlone kolejne fragmenty bajki Hansa Christiana Andersena <b>"Mała Syrenka"</b>,
              gdy zobaczysz tekst wyraźnie, naciśnij spację i obróć flipper.
              W momencie gdy tekst bajki zobaczysz wyraźnie przez ujemną soczewkę flippera, ponownie naciśnij spację i odwróć flipper.
              Powtarzaj te czynności do końca ćwiczenia.
            </div>
          </div>
          <div className="toast-text-underlined">
            <small className="text-justify">
              <b>Rozpoczęcie ćwiczenia spowoduje uruchomienie trybu pełnoekranowego przeglądarki</b>
            </small>
          </div>
          <Button onClick={async () => { await runCamera(); enableFullScreen(); }} className="btn btn-dark mt-3 w-100">Kontynuuj</Button>
        </CustomToast>
        <CustomToast title="Ćwiczenie sprawności akomodacji" className="pb-5" toggle={initialMessageSecond}>
          <div className="mb-2">
            <div className="toast-text-underlined mb-1">
              <small className="text-justify">
                <b>Przed rozpoczęciem ćwiczenia przyznaj uprawnienia dostępu do kamery</b>
              </small>
            </div>
            <div className="text-justify">
              Twoja pozycja od ekranu powinna wynosić {constantDistanceToScreenDuringExercise} centymetrów. Komunikaty na ekranie umożliwią dostosowanie właściwej odległości.
            </div>
            <div className="text-justify">
              Zachowuj stałą odległość od kamery i postępuje zgodnie z powiadomieniami na ekranie
            </div>
            <div className="toast-text-underlined mt-1">
              <small className="text-justify">
                <b>Rozpocznij ćwiczenie dla oka prawego wykorzystując dodatnie soczewki</b>
              </small>
            </div>
          </div>
          <Button onClick={startBeginningSequence} className={`btn btn-dark mt-3 w-100 ${(addedCameraPriviledges && initialMessageSecond) ? '' : 'disabled'}`}>Rozpocznij ćwiczenie</Button>
        </CustomToast>
        <CustomToast title="Ćwiczenie sprawności akomodacji" className="pb-5" toggle={showInfoTestSecondEye}>
          <div className="mb-4">
            <div className="toast-text-underlined">
              <small className="text-justify">
                Zakończono ćwiczenie dla prawego oka.
              </small>
            </div>
            <div className="text-justify mt-3">
              Rozpocznij ćwiczenie dla oka lewego wykorzystując dodatnie soczewki
            </div>
          </div>
          <Button onClick={() => { setShowInfoTestSecondEye(false); setStartExerciseForLeftEye(true) }} className="btn btn-dark mt-3 w-100">Rozpocznij ćwiczenie</Button>
        </CustomToast>
        <CustomToast title="Ćwiczenie sprawności akomodacji" className="pb-5" toggle={finishExercise}>
          <div className="mb-1 text-center">
            Ćwiczenie zostało zakończone
          </div>
          <Button onClick={() => { disableFullScreen();}} className="btn btn-dark mt-3 w-100">Powrót do menu</Button>
        </CustomToast>
        <Fade in={initialMessageSecond || startExercise}>
          <div className="video-container">
            <video ref={video} className="debug-video" id="video"></video>
          </div>
        </Fade>
        <div className={`text-center ${showDistanceAlert ? 'show' : 'fade'} alert-centered`}>
          <Alert ref={distanceToScreen} variant={'danger'} >
            <Alert.Heading></Alert.Heading>
          </Alert>
        </div>
        <Fade in={startExerciseForRightEye || startExerciseForLeftEye}>
          <div className="text-container-centered">
          {!finishExercise && (
              <div
                  className="text-amplitude-accommodation amplitude-accommodation-lefts"
                  style={{
                      fontSize: `10px`,
                  }}
              >
                  <span id="text-space" style={{ padding: '20px'}}>{storyLines[lastStoryLine.current]?.text ?? 'test'}</span>
              </div>
          )}
          </div>
        </Fade>
        <div className={`${showRotateFlipperInfo ? 'show' : 'fade'} alert-centered`}>
          <Alert ref={rotateFlipperInfo} variant={'info'} >
            <Alert.Heading></Alert.Heading>
          </Alert>
        </div>
      </main>
    </>
  )
}
