import InfoBox from '@/Components/InfoBox';
import StyledBreadcrumb from '@/Components/StyledBreadcrumb';
import AdminAuthenticatedLayout from '@/Layouts/AdminAuthenticatedLayout';
import { Head, router } from '@inertiajs/react';
import { Breadcrumbs, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import { PieChart } from '@mui/x-charts/PieChart';
import ChartBox from '@/Components/ChartBox';
import { BarChart } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts';

export default function AdminDashboard({ auth, patientsCount, finishedExercisesSum, exercisesStagesWithSumOfExercises, lastExerciseDate }) {
    return (
        <AdminAuthenticatedLayout
            user={auth.user}
            header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        component="a"
                        onClick={() => router.visit(`/admin/dashboard`)}
                        href="javascript:void(0)"
                        label="Panel"
                        icon={<HomeIcon fontSize="small" />}
                    />
                </Breadcrumbs>
            </h2>}
        >
            <Head title="Panel" />
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg p-3">
                        <Box
                            sx={{
                                bgcolor: '#F3F4F6',
                                boxShadow: '1px 1px 5px 1px #80808045',
                                borderRadius: 2,
                                p: 1,
                                mb: 2,
                            }}
                        >
                            <Box sx={{ color: '#46505A' }}>
                                <Typography variant="h5" sx={{ p: 0, textAlign: 'center' }}>STATYSTYKI</Typography>
                            </Box>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <InfoBox title="Liczba użytkowników" value={patientsCount}></InfoBox>
                            </Grid>
                            <Grid item xs={4}>
                                <InfoBox title="Łączna liczba wykonanych ćwiczeń" value={finishedExercisesSum.reduce((acc, obj) => acc + obj['total'], 0)}></InfoBox>
                            </Grid>
                            <Grid item xs={4}>
                                <InfoBox title="Data wykonania ostatniego ćwiczenia" value={lastExerciseDate}></InfoBox>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg p-3 mt-2">
                        <Grid container>
                            <Grid item xs={12}>
                                <ChartBox title="Liczba wykonanych poszczególnych ćwiczeń">
                                    <PieChart
                                        series={[
                                            {
                                                data: finishedExercisesSum.map((obj, idx) => ({
                                                    id: idx,
                                                    value: obj['total'],
                                                    label: obj['name']
                                                })),
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                                paddingAngle: 2,
                                            },
                                        ]}
                                        width={800}
                                        height={200}
                                        />
                                </ChartBox>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg p-3 mt-2">
                        <Grid container>
                            <Grid item xs={12}>
                                <ChartBox title="Liczba wykonanych ćwiczeń w zależności od poziomu">
                                    <BarChart
                                        dataset={exercisesStagesWithSumOfExercises}
                                        xAxis={[{ scaleType: 'band', dataKey: 'stage', valueFormatter: (v) => `Poziom ${v}`  }]}
                                        series={[
                                            ...Object.keys(exercisesStagesWithSumOfExercises[0] ?? []).filter((obj) => obj !== 'stage').map((obj) => ({
                                                dataKey: obj,
                                                label: obj
                                            }))
                                        ]}
                                        yAxis={[
                                            {
                                                label: 'Liczba wykonanych ćwiczeń',
                                            }
                                        ]}

                                        height={300}
                                        sx={{
                                            [`.${axisClasses.left} .${axisClasses.label}`]: {
                                                transform: 'translate(-10px, 0)',
                                            },
                                        }}
                                        margin={{ top: 80 }}
                                        slotProps={{
                                            legend: {
                                              direction: 'row',
                                              position: {
                                                vertical: 'top',
                                                horizontal: 'middle',
                                              },
                                            }
                                          }}
                                    />
                                </ChartBox>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </AdminAuthenticatedLayout>
    );
}
