import UserListDataTable from '@/Components/UserListDataTable';
import AdminAuthenticatedLayout from '@/Layouts/AdminAuthenticatedLayout';
import { Breadcrumbs } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HomeIcon from '@mui/icons-material/Home';
import StyledBreadcrumb from '@/Components/StyledBreadcrumb';
import { router } from '@inertiajs/react';
import { useState } from 'react';

export default function UsersList({ auth, users, exerciseProperties }) {
    const [isTester, setIsTester] = useState(() => Boolean(auth.user.roles.filter((obj) => obj.name === 'tester').length));

    return (
        <AdminAuthenticatedLayout
            user={auth.user}
            header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">
                <Breadcrumbs aria-label="breadcrumb">
                    {!isTester && <StyledBreadcrumb
                            component="a"
                            onClick={() => router.visit("/admin/dashboard")}
                            href="javascript:void(0)"
                            label="Panel"
                            icon={<HomeIcon fontSize="small" />}
                        />
                    }
                    <StyledBreadcrumb
                        component="a"
                        onClick={() => router.visit(`/admin/users`)}
                        href="javascript:void(0)"
                        label={isTester ? "Wyniki" : "Użytkownicy"}
                        icon={<PeopleAltIcon fontSize="small" />}
                    />
                </Breadcrumbs>
            </h2>}
        >
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <UserListDataTable user={auth.user} data={users} exerciseProperties={exerciseProperties}></UserListDataTable>
                    </div>
                </div>
            </div>
        </AdminAuthenticatedLayout>
    );
}
