class CalculateSizeOfTheScreen {

    constructor({ scaleFactor: scaleFactor, screenInchSize: screenInchSize = 14.1}) {
        this.screenWidth = window.screen.width;
        this.screenHeight = window.screen.height;
        this.ratio = this.screenWidth / this.screenHeight;
        this.screenInchSize = screenInchSize;
        this.multiplier = this.screenInchSize / Math.sqrt(Math.pow(this.ratio, 2));
        this.realScreenHeight = this.multiplier * 2.54;
        this.realScreenWidth = this.multiplier * this.ratio * 2.54;
        this.scaleFactor = scaleFactor;
    }

    screenDividers() {
        return {
            width: Math.floor(this.realScreenWidth * this.scaleFactor),
            height: Math.floor(this.realScreenHeight * this.scaleFactor)
        }
    }

    screenOffsets() {
        return {
            width: Math.floor(((this.screenWidth / this.scaleFactor) / Math.floor(this.realScreenWidth) - (this.screenWidth / this.scaleFactor) / this.realScreenWidth) * (Math.floor(this.realScreenWidth) * this.scaleFactor / 2)),
            height: Math.floor(((this.screenHeight / this.scaleFactor) / Math.floor(this.realscreenHeight) - (this.screenHeight / this.scaleFactor) / this.realScreenHeight) * (Math.floor(this.realScreenHeight) * this.scaleFactor / 2))
        }
    }

    cellDimensions() {
        return {
            width: Math.floor(this.screenWidth / Math.floor(this.realScreenWidth * this.scaleFactor)),
            height: Math.floor(this.screenHeight / Math.floor(this.realScreenHeight * this.scaleFactor))
        }
    }
}

export { CalculateSizeOfTheScreen };