import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export default function ChartBox(props) {
  return (
      <Box
        sx={{
          bgcolor: '#fff',
          boxShadow: '1px 1px 5px 1px #80808045',
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box
            sx={{
                bgcolor: '#F3F4F6',
                boxShadow: '1px 1px 5px 1px #80808045',
                borderRadius: 2,
                p: 1,
                mb: 2,
            }}
        >
          <Box sx={{ color: '#46505A', mb: 1 }}>
            <Typography variant="h5" sx={{ p: 0, textAlign: 'center' }}>{props.title}</Typography>
          </Box>
        </Box>
        <Box sx={{ color: '#173A5E', alignContent: 'center' }}>
            {props.children}
        </Box>
      </Box>
    );
}