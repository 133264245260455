import CustomToast from "@/Components/CustomToast";
import { useEffect, useRef, useState } from "react";
import { Alert, Button, Fade } from "react-bootstrap";
import FaceDetectionEngine from "@/libs/faceDetectionEngine";
import { CalculateSizeOfTheScreen } from "@/libs/screenSize";
import { router } from "@inertiajs/react";
import axios from "axios";
import description1 from "%/sounds/exercises/amplitude_of_eye_accommodation/description_1.mp3";
import description2 from "%/sounds/exercises/amplitude_of_eye_accommodation/description_2.mp3";
import description3 from "%/sounds/exercises/amplitude_of_eye_accommodation/description_3.mp3";
import exerciseNameDescription from "%/sounds/exercises/amplitude_of_eye_accommodation/exercise_name_description.mp3";
import exerciseHasBeenFinished from "%/sounds/components/exercise_has_been_finished.mp3";
import doNextIterationFile from "%/sounds/components/do_next_iteration.mp3";

export default function AmplitudeOfEyeAccommodation({
    auth,
    exercise,
    constantDistanceToScreenDuringExercise,
    word,
    letter,
}) {
    const [initialMessage, setInitialMessage] = useState(false);
    const [initialMessageSecond, setInitialMessageSecond] = useState(false);
    const [startExercise, setStartExercise] = useState(false);
    const [addedCameraPriviledges, setAddedCameraPriviledges] = useState(false);
    const [finishExercise, setFinishExercise] = useState(false);
    const isMounted = useRef(false);
    const video = useRef(null);
    const faceDetection = useRef(null);
    const [doNextIteration, setDoNextIteration] = useState(false);
    const [showRotateFlipperInfo, setShowRotateFlipperInfo] = useState(false);
    const rotateFlipperInfo = useRef(null);
    const [startExerciseForRightEye, setStartExerciseForRightEye] =
        useState(false);
    const [startExerciseForLeftEye, setStartExerciseForLeftEye] =
        useState(false);
    const [showMeasureInfoRigthEye, setShowMeasureInfoRigthEye] =
        useState(false);
    const [showMeasureInfoLeftEye, setShowMeasureInfoLeftEye] = useState(false);
    const requiredCycles = useRef(exercise?.properties?.cycles ?? 5);
    const sizeOfPoint = useRef(exercise?.properties?.size_of_point ?? 1);
    const currentCycleRight = useRef(1);
    const currentCycleLeft = useRef(1);
    const [currentCycle, setCurrentCycle] = useState(1);
    const [measureResults, setMeasureResults] = useState({
        left_eye: {
            distance: [],
        },
        right_eye: {
            distance: [],
        },
    });
    const oneCmIsEqual = useRef({
        width: 1,
        height: 1,
    });
    const textContainer = useRef(null);

    const description1Audio = useRef(new Audio(description1));
    const description2Audio = useRef(new Audio(description2));
    const description3Audio = useRef(new Audio(description3));
    const exerciseNameDescriptionAudio = useRef(
        new Audio(exerciseNameDescription)
    );
    const exerciseHasBeenFinishedAudio = useRef(
        new Audio(exerciseHasBeenFinished)
    );
    const doNextIterationAudio = useRef(new Audio(doNextIterationFile));
    const backgroundAnimation = useRef(null);
    const background = useRef(null);
    const arrayWithNumbers = useRef([]);

    useEffect(() => {
        if (isMounted.current && startExercise === true) {
            setStartExerciseForRightEye(true);
        }
    }, [startExercise]);

    const spaceEventHandler = (e) => {
        if (e.code === "Space") {
            if (startExerciseForRightEye) {
                rightEyeMeasured(faceDetection.current.distanceToScreen);
            } else if (startExerciseForLeftEye) {
                leftEyeMeasured(faceDetection.current.distanceToScreen);
            }
        }
    };

    const moveAnimationForward = () => {
        backgroundAnimation.current?.pause();
        backgroundAnimation.current = background.current.animate(
            [
                {
                    transform: `scale(${
                        background.current.getBoundingClientRect().width /
                        background.current.offsetWidth
                    })`,
                },
                {
                    transform: "scale(1.3)",
                },
            ],
            {
                duration: 200,
                iterations: 1,
                fill: "forwards",
            }
        );
    };

    const moveAnimationBack = () => {
        backgroundAnimation.current?.pause();
        backgroundAnimation.current = background.current.animate(
            [
                {
                    transform: `scale(${
                        background.current.getBoundingClientRect().width /
                        background.current.offsetWidth
                    })`,
                },
                {
                    transform: "scale(1.0)",
                },
            ],
            {
                duration: 5000,
                iterations: 1,
                fill: "forwards",
            }
        );
    };

    const rightEyeMeasured = (distanceToScreen) => {
        currentCycleRight.current++;
        setCurrentCycle(currentCycleRight.current);
        setDoNextIteration(true);

        setMeasureResults(() => {
            const newData = measureResults;
            newData["right_eye"]["distance"].push(distanceToScreen);
            return newData;
        });

        if (currentCycleRight.current - 1 === requiredCycles.current) {
            document.removeEventListener("keyup", spaceEventHandler);
            setStartExerciseForRightEye(false);
            setShowMeasureInfoLeftEye(true);
            setCurrentCycle(1);
        } else {
            setDoNextIteration(true);
        }
    };

    const leftEyeMeasured = (distanceToScreen) => {
        currentCycleLeft.current++;
        setCurrentCycle(currentCycleLeft.current);

        setMeasureResults(() => {
            const newData = measureResults;
            newData["left_eye"]["distance"].push(distanceToScreen);
            return newData;
        });

        if (currentCycleLeft.current - 1 === requiredCycles.current) {
            document.removeEventListener("keyup", spaceEventHandler);
            setFinishExercise(true);
        } else {
            setDoNextIteration(true);
        }
    };

    useEffect(() => {
        if (isMounted.current && doNextIteration) {
            moveAnimationForward();
            doNextIterationAudio.current.currentTime = 0;
            doNextIterationAudio.current.pause();
            doNextIterationAudio.current.play();

            setTimeout(() => {
                setDoNextIteration(false);
                moveAnimationBack();
            }, 1500);
        }
    }, [doNextIteration]);

    useEffect(() => {
        if (isMounted.current && startExerciseForRightEye) {
            document.addEventListener("keyup", spaceEventHandler);
        }
    }, [startExerciseForRightEye]);

    useEffect(() => {
        if (isMounted.current && startExerciseForLeftEye) {
            faceDetection.current.callback =
                "measureDistanceToScreenForLeftEye";
            document.addEventListener("keyup", spaceEventHandler);
        }
    }, [startExerciseForLeftEye]);

    const stopExercise = () => {
        const tracks = video.current.srcObject?.getTracks() ?? [];
        tracks.forEach((track) => {
            track.stop();
        });

        video.current.srcObject = null;
    };

    useEffect(() => {
        if (isMounted.current && finishExercise === true) {
            doNextIterationAudio.current.currentTime = 0;
            doNextIterationAudio.current.pause();
            exerciseHasBeenFinishedAudio.current.play();
            stopExercise();
        }
    }, [finishExercise]);

    useEffect(() => {
        isMounted.current = true;
        const sizeOfTheScreen = new CalculateSizeOfTheScreen({
            scaleFactor: 1,
            screenInchSize: auth.user.screen_inch,
        });
        oneCmIsEqual.current = sizeOfTheScreen.cellDimensions();
        setInitialMessage(true);
        arrayWithNumbers.current = arrayOfLetterAmount(word, letter);
    }, []);

    const runExercise = async () => {
        faceDetection.current.callback = "measureDistanceToScreenForRightEye";
        await faceDetection.current.runPredictionLoop();
    };

    const runCamera = async () => {
        setInitialMessage(false);
        setInitialMessageSecond(true);

        await navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: {
                    facingMode: "user",
                },
            })
            .then((stream) => {
                setAddedCameraPriviledges(true);
                video.current.srcObject = stream;
            });

        video.current.oncanplay = async (e) => {
            faceDetection.current = new FaceDetectionEngine({
                video: video.current,
                focalLength: auth.user.focal_length ?? 68,
                constDistanceDuringExercise:
                    constantDistanceToScreenDuringExercise,
            });
            await faceDetection.current.initializePrediction();
            video.current.play();

            setTimeout(() => {
                runExercise();
            }, 200);
        };
    };

    const startBeginningSequence = async () => {
        setInitialMessageSecond(false);
        setStartExercise(true);
    };

    const enableFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        }
    };

    const disableFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            router.visit("/dashboard");
        }
    };

    const selectedNumber = (number) => {
        disableFullScreen();
        axios.post(location.href, {
            left_eye_distance: Array.from(
                measureResults.left_eye.distance
            ).toString(),
            right_eye_distance: Array.from(
                measureResults.right_eye.distance
            ).toString(),
            letter_test: countAmountOfLetters(word, letter) === number,
        });
    };

    const countAmountOfLetters = (word, letter) => {
        return word.split("").filter((wordLetter) => wordLetter === letter)
            .length;
    };

    const arrayOfLetterAmount = (word, letter) => {
        let result = [countAmountOfLetters(word, letter)];
        while (true) {
            const number = Math.floor(Math.random() * (word.length - 1));
            if (
                result.filter((numberInArray) => numberInArray === number)
                    .length === 0
            ) {
                result.push(number);
            }

            if (result.length === 3) {
                break;
            }
        }

        return result.sort((a, b) => 0.5 - Math.random());
    };

    useEffect(() => {
        if (isMounted.current) {
            if (initialMessage) {
                setTimeout(() => {
                    exerciseNameDescriptionAudio.current.play();
                }, 500);
                exerciseNameDescriptionAudio.current.onended = function () {
                    description1Audio.current.play();
                };
            } else {
                exerciseNameDescriptionAudio.current.currentTime = 0;
                exerciseNameDescriptionAudio.current.pause();
                description1Audio.current.currentTime = 0;
                description1Audio.current.pause();
            }
        }
    }, [initialMessage]);

    useEffect(() => {
        if (isMounted.current) {
            if (initialMessageSecond) {
                description2Audio.current.play();
            } else {
                description2Audio.current.currentTime = 0;
                description2Audio.current.pause();
            }
        }
    }, [initialMessageSecond]);

    useEffect(() => {
        if (isMounted.current) {
            if (showMeasureInfoLeftEye) {
              doNextIterationAudio.current.currentTime = 0;
              doNextIterationAudio.current.pause();
                description3Audio.current.play();
            } else {
                description3Audio.current.currentTime = 0;
                description3Audio.current.pause();
            }
        }
    }, [showMeasureInfoLeftEye]);

    return (
        <>
            <main className="full-view-for-exercises">
                <div id="wrap">
                    <div ref={background} id="route-background"></div>
                </div>
                <CustomToast
                    title="Ćwiczenie amplitudy akomodacji"
                    className="pb-5"
                    toggle={initialMessage}
                >
                    <div className="mb-3">
                        <div className="text-justify">
                            Celem ćwiczenia jest określenie punktu bliskiego
                            akomodacji, będącym najmiejszą odległością, w której
                            możesz zobaczyć wyraźnie wyświetlony tekst.
                        </div>
                    </div>
                    <div className="toast-text-underlined">
                        <small><b>Postępuj zgodnie ze wskazówkami wyświetlonymi na ekranie</b></small>
                    </div>
                    <div className="toast-text-underlined mt-2">
                        <small>
                            <b>
                                Rozpoczęcie ćwiczenia spowoduje uruchomienie
                                trybu pełnoekranowego przeglądarki
                            </b>
                        </small>
                    </div>
                    <Button
                        onClick={async () => {
                            await runCamera();
                            enableFullScreen();
                        }}
                        className="btn btn-dark mt-3 w-100"
                    >
                        Kontynuuj
                    </Button>
                </CustomToast>
                <CustomToast
                    title="Ćwiczenie amplitudy akomodacji"
                    className="pb-5"
                    toggle={initialMessageSecond}
                >
                    <div className="mb-2">
                        <div className="toast-text-underlined">
                            <small>
                                <b>
                                    Przed rozpoczęciem ćwiczenia przyznaj
                                    uprawnienia dostępu do kamery
                                </b>
                            </small>
                        </div>
                        <div className="text-center mt-3">
                            <b>Rozpocznij ćwiczenie dla oka prawego</b>
                        </div>
                        <div className="text-justify mt-3">
                            Kliknij poniższy przycisk i przytknij nos do
                            wyświetlonego punktu, a następnie powoli oddalaj
                            głowę. Jeśli zobaczysz tekst wyraźnie naciśnij
                            spację. Zapamiętaj wyświetlone słowo. Na koniec
                            ćwiczenia czeka na Ciebie zagadka.
                        </div>
                        <div className="toast-text-underlined mt-3">
                            <small>
                                <b>
                                    Ćwiczenie powtórz {requiredCycles.current}{" "}
                                    razy dla każdego oka
                                </b>
                            </small>
                        </div>
                    </div>
                    <Button
                        onClick={startBeginningSequence}
                        className={`btn btn-dark mt-3 w-100 ${
                            addedCameraPriviledges && initialMessageSecond
                                ? ""
                                : "disabled"
                        }`}
                    >
                        Rozpocznij ćwiczenie
                    </Button>
                </CustomToast>
                <CustomToast
                    title="Ćwiczenie amplitudy akomodacji"
                    className="pb-5"
                    toggle={showMeasureInfoLeftEye}
                >
                    <div className="mb-2">
                        <div className="toast-text-underlined">
                            <small>
                                <b>Zakończono etap ćwiczenia oka prawego</b>
                            </small>
                        </div>
                        <div className="text-center mt-3">
                            Rozpocznij kolejny etap ćwiczenia oka lewego
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            setShowMeasureInfoLeftEye(false);
                            setStartExerciseForLeftEye(true);
                        }}
                        className="btn btn-dark mt-3 w-100"
                    >
                        Rozpocznij ćwiczenie
                    </Button>
                </CustomToast>
                <CustomToast
                    title="Ćwiczenie amplitudy akomodacji"
                    className="pb-5"
                    toggle={finishExercise}
                >
                    <div className="mb-2 text-center">Zakończono ćwiczenie</div>
                    <div className="mb-2">
                        <div className="toast-text-underlined">
                            <small>
                                Ile liter <b>{letter}</b> znajdowało się w
                                wyświetlonym słowie?
                            </small>
                        </div>
                    </div>
                    <div
                        class="mt-3"
                        style={{ display: "flex", columnGap: "4px" }}
                    >
                        {arrayWithNumbers.current.map((number) => (
                            <Button
                                onClick={() => {
                                    selectedNumber(number);
                                }}
                                className="btn btn-dark"
                                style={{ width: "100%" }}
                            >
                                {number}
                            </Button>
                        ))}
                    </div>
                </CustomToast>
                <Fade in={initialMessageSecond || startExercise}>
                    <div className="video-container">
                        <video
                            ref={video}
                            className="debug-video"
                            id="video"
                        ></video>
                    </div>
                </Fade>
                <div
                    className={`${
                        doNextIteration ? "show" : "fade"
                    } convergence-zoom-popup`}
                >
                    <Alert variant={"warning"}>
                        <Alert.Heading>
                            Wykonaj kolejne powtórzenie
                        </Alert.Heading>
                    </Alert>
                </div>
                <Fade in={initialMessageSecond || startExercise}>
                    <div
                        ref={textContainer}
                        className="text-container-amplitude-accommodation"
                    >
                        {!finishExercise && (
                            <div
                                className="text-amplitude-accommodation amplitude-accommodation-lefts"
                                style={{
                                    fontSize: `${parseInt(
                                        8 * sizeOfPoint.current
                                    )}px`,
                                }}
                            >
                                <span id="text-space">{word}</span>
                            </div>
                        )}
                        <div
                            id="pointer"
                            style={{
                                top: `calc(${parseInt(
                                    oneCmIsEqual.current.height * 2
                                )}px - 0.5in)`,
                            }}
                            className="pointer-amplitude-accommodation amplitude-accommodation-lefts"
                        ></div>
                    </div>
                </Fade>
                <div
                    className={`${
                        showRotateFlipperInfo ? "show" : "fade"
                    } alert-centered`}
                >
                    <Alert ref={rotateFlipperInfo} variant={"info"}>
                        <Alert.Heading></Alert.Heading>
                    </Alert>
                </div>
                <Fade
                    in={initialMessageSecond || startExercise}
                    className="badge bg-dark convergence-counter-indicator"
                >
                    <div>
                        <h1>{currentCycle - 1}</h1>
                        <h5>POWTÓRZENIE</h5>
                    </div>
                </Fade>
            </main>
        </>
    );
}
