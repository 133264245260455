import * as React from "react";
import { BarChart, axisClasses } from "@mui/x-charts";

export default function EyeMovementBarChart(props) {
    return (
        <BarChart
            dataset={props.data}
            xAxis={[{ scaleType: "band", dataKey: "probe", valueFormatter: (v) => `Iteracja ${v}` }]}
            series={[
                ...Object.keys(props.data[0] ?? [])
                    .filter((obj) => obj !== "probe")
                    .map((obj) => ({
                        dataKey: obj,
                        label: obj,
                    })),
            ]}
            yAxis={[
                {
                    label: props.yLabel ?? '',
                },
            ]}
            height={300}
            sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translate(-10px, 0)",
                },
            }}
            margin={{ top: 80 }}
            slotProps={{
                legend: {
                    direction: "row",
                    position: {
                        vertical: "top",
                        horizontal: "middle",
                    },
                },
            }}
        />
    );
}
