import CustomToast from "@/Components/CustomToast";
import { useEffect, useRef, useState } from "react";
import { Alert, Button, Fade } from "react-bootstrap";
import FaceDetectionEngine from "@/libs/faceDetectionEngine";
import { CalculateSizeOfTheScreen } from "@/libs/screenSize";
import { router } from '@inertiajs/react';
import axios from 'axios';
import description1 from "%/sounds/exercises/convergence_of_eye/description_1.mp3";
import description2 from "%/sounds/exercises/convergence_of_eye/description_2.mp3";
import exerciseNameDescription from "%/sounds/exercises/convergence_of_eye/exercise_name_description.mp3";
import exerciseHasBeenFinished from "%/sounds/components/exercise_has_been_finished.mp3";
import moveYourHeadAway from "%/sounds/components/move_your_head_away.mp3";
import moveYourHeadCloser from "%/sounds/components/move_your_head_closer.mp3";
import worm from "%/icons/worm.svg";

export default function ConvergenceOfEye({auth, exercise, constantDistanceToScreenDuringExercise}) {
  const [initialMessage, setInitialMessage] = useState(false);
  const [initialMessageSecond, setInitialMessageSecond] = useState(false);
  const [startExercise, setStartExercise] = useState(false);
  const [addedCameraPriviledges, setAddedCameraPriviledges] = useState(false);
  const [finishExercise, setFinishExercise] = useState(false);
  const isMounted = useRef(false);
  const video = useRef(null);
  const faceDetection = useRef(null);
  const zoomInfo = useRef(null);
  const [startExerciseForZoomIn, setStartExerciseForZoomIn] = useState(false);
  const [startExerciseForZoomOut, setStartExerciseForZoomOut] = useState(false);
  const [showAlertInfo, setShowAlertInfo] = useState(false);
  const [measureResults, setMeasureResults] = useState({
    cycles: []
  });
  const cycleResult = {
    zoomIn: {
      loosedFixation: false,
      distance: null
    },
    zoomOut: {
      loosedFixation: false,
      distance: null
    },
  }
  const oneCmIsEqual = useRef({
    width: 1,
    height: 1,
  });
  const textContainer = useRef(null);
  const currentCycle = useRef(1);
  const requiredCycles = useRef(exercise?.properties?.cycles ?? 5);
  const zoomInHead = useRef(false);
  const zoomOutHead = useRef(false);
  let alertTimeout;
  const description1Audio = useRef(new Audio(description1));
  const description2Audio = useRef(new Audio(description2));
  const exerciseNameDescriptionAudio = useRef(new Audio(exerciseNameDescription));
  const exerciseHasBeenFinishedAudio = useRef(new Audio(exerciseHasBeenFinished));
  const moveYourHeadAwayAudio = useRef(new Audio(moveYourHeadAway));
  const moveYourHeadCloserAudio = useRef(new Audio(moveYourHeadCloser));
  const backgroundAnimation = useRef(null);
  const background = useRef(null);

  useEffect(() => {
    if (isMounted.current && startExercise) {
      setStartExerciseForZoomIn(true);
    }
  }, [startExercise]);

  const spaceEventHandler = (e) => {
    if (e.code === 'Space') {
      if (startExerciseForZoomIn) {
        zoomInMeasured(faceDetection.current.distanceToScreen);
      } else if (startExerciseForZoomOut) {
        zoomOutMeasured(faceDetection.current.distanceToScreen);
      }
    }
  }

  const zoomInMeasured = (distanceToScreen) => {
    currentCycle.current++;
    setStartExerciseForZoomIn(false);
    setMeasureResults(() => {
      const newData = measureResults;
      const result = { ...cycleResult };
      result.zoomIn.distance = distanceToScreen;
      console.log(newData, result);
      newData['cycles'].push(result);
      return newData;
    });
    console.log(distanceToScreen);
    document.removeEventListener('keyup', spaceEventHandler);

    setStartExerciseForZoomOut(true);
  }

  const zoomOutMeasured = (distanceToScreen) => {
    setStartExerciseForZoomOut(false);

    setMeasureResults(() => {
      const newData = measureResults;
      newData['cycles'][newData.cycles.length - 1].zoomOut.distance = distanceToScreen;
      return newData;
    });
    console.log(distanceToScreen);
    document.removeEventListener('keyup', spaceEventHandler);
    // video.current.removeEventListener('distance', zoomOutMeasured);

    setStartExerciseForZoomIn(true);
  }

  useEffect(() => {
    if (isMounted.current && startExerciseForZoomIn) {
      if ((currentCycle.current - 1) === requiredCycles.current) {
        setStartExerciseForZoomIn(false);
        setFinishExercise(true);
      } else {
        backgroundAnimation.current?.pause();
        backgroundAnimation.current = background.current.animate([
          {
            transform: `scale(${background.current.getBoundingClientRect().width / background.current.offsetWidth})`,
          },
          {
            transform: 'scale(1.5)',
          },
        ], {
          duration: 5000,
          iterations: 1,
          fill: 'forwards'
        });

        document.addEventListener('keyup', spaceEventHandler);
        clearTimeout(alertTimeout);
        alertTimeout = runAlertTimeout();
        // video.current.addEventListener('distance', zoomInMeasured);
      }
    }
  }, [startExerciseForZoomIn]);

  useEffect(() => {
    if (isMounted.current && startExerciseForZoomOut) {
      backgroundAnimation.current?.pause();
      backgroundAnimation.current = background.current.animate([
        {
          transform: `scale(${background.current.getBoundingClientRect().width / background.current.offsetWidth})`,
        },
        {
          transform: 'scale(1.0)',
        },
      ], {
        duration: 5000,
        iterations: 1,
        fill: 'forwards'
      });

      document.addEventListener('keyup', spaceEventHandler);
      clearTimeout(alertTimeout);
      alertTimeout = runAlertTimeout();
      // video.current.addEventListener('distance', zoomOutMeasured);
    }
  }, [startExerciseForZoomOut]);

  const stopExercise = () => {
    const tracks = video.current.srcObject?.getTracks() ?? [];
    tracks.forEach((track) => {
      track.stop();
    });
    video.current.srcObject = null;
  }

  useEffect(() => {
    if (isMounted.current && finishExercise === true) {
      exerciseHasBeenFinishedAudio.current.play();
      console.log(measureResults);
      stopExercise();

      axios.post(location.href, {
        zoom_in: JSON.stringify(measureResults.cycles.map((obj) => obj.zoomIn)),
        zoom_out: JSON.stringify(measureResults.cycles.map((obj) => obj.zoomOut))
      });
    }

  }, [finishExercise]);

  useEffect(() => {
    isMounted.current = true;
    const sizeOfTheScreen = new CalculateSizeOfTheScreen({
      scaleFactor: 1,
      screenInchSize: auth.user.screen_inch
    });

    oneCmIsEqual.current = sizeOfTheScreen.cellDimensions();
    setInitialMessage(true);
  }, []);

  const runExercise = async () => {
    await faceDetection.current.runPredictionLoop();
  };

  const runCamera = async () => {
    setInitialMessage(false);
    setInitialMessageSecond(true);

    await navigator.mediaDevices.getUserMedia({
      'audio': false,
      'video': {
        facingMode: 'user',
      }
    }).then(stream => {
      setAddedCameraPriviledges(true);
      video.current.srcObject = stream;
    });

    video.current.oncanplay = async (e) => {
      faceDetection.current = new FaceDetectionEngine({
        video: video.current,
        focalLength: auth.user.focal_length ?? 68,
        callback: 'detectLoosingFixation',
        constDistanceDuringExercise: constantDistanceToScreenDuringExercise
      });
      await faceDetection.current.initializePrediction();

      video.current.play();

      setTimeout(() => {
        runExercise();
      }, 200);
    };
  }

  const runAlertTimeout = () => {
    setShowAlertInfo(true);
    return setTimeout(() => {
      setShowAlertInfo(false);
    }, 1500);
  }

  const startBeginningSequence = async () => {
    setInitialMessageSecond(false);
    setStartExercise(true);
  };

  const enableFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  }

  const disableFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      router.visit('/dashboard');
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      if (initialMessage) {
        setTimeout(() => {
          exerciseNameDescriptionAudio.current.play();
        }, 500);
        exerciseNameDescriptionAudio.current.onended = function() {
          description1Audio.current.play();
        };
      } else {
        console.log('xd', description1Audio);
        exerciseNameDescriptionAudio.current.currentTime = 0;
        exerciseNameDescriptionAudio.current.pause();
        description1Audio.current.currentTime = 0;
        description1Audio.current.pause();
      }
    }
  }, [initialMessage]);

  useEffect(() => {
    if (isMounted.current) {
      if (initialMessageSecond) {
        description2Audio.current.play();
      } else {
        description2Audio.current.currentTime = 0;
        description2Audio.current.pause();
      }
    }
  }, [initialMessageSecond]);

  useEffect(() => {
    if (isMounted.current && showAlertInfo) {
      if (startExerciseForZoomIn) {
        moveYourHeadAwayAudio.current.currentTime = 0;
        moveYourHeadAwayAudio.current.pause();
        moveYourHeadCloserAudio.current.play();
      } else if (startExerciseForZoomOut) {
        moveYourHeadCloserAudio.current.currentTime = 0;
        moveYourHeadCloserAudio.current.pause();
        moveYourHeadAwayAudio.current.play();
      }
    }
  }, [showAlertInfo]);

  return (
    <>
      <main className="full-view-for-exercises">
        <div id="wrap">
          <div ref={background} id="forest-background"></div>
        </div>
        <CustomToast title="Ćwiczenie konwergencji" className="pb-5" toggle={initialMessage}>
          <div className="mb-3">
            <div className="text-justify">Celem ćwiczenia jest określenie punktu bliskiego konwergencji, będącym najmiejszą odległością,
              w której możesz zobaczyć wyraźnie i pojedynczo obrazek robaczka.
            </div>
          </div>
          <div className="toast-text-underlined">
              <small><b>Postępuj zgodnie ze wskazówkami wyświetlonymi na ekranie</b></small>
          </div>
          <div className="toast-text-underlined mt-2">
            <small><b>Rozpoczęcie ćwiczenia spowoduje uruchomienie trybu pełnoekranowego przeglądarki</b></small>
          </div>
          <Button onClick={async () => { await runCamera(); enableFullScreen(); }} className="btn btn-dark mt-3 w-100">Kontynuuj</Button>
        </CustomToast>
        <CustomToast title="Ćwiczenie konwergencji" className="pb-5" toggle={initialMessageSecond}>
          <div className="mb-1">
            <div className="toast-text-underlined">
              <small className="text-justify">
                <b>Przed rozpoczęciem ćwiczeni przyznaj uprawnienia dostępu do kamery</b>
              </small>
            </div>
            <div className="text-justify mt-1">
              Powoli przybliżaj głowę w kierunku wyświetlonej na ekranie <b style={{color: 'var(--red-pointer-color)'}}>czerwonej kropki</b> patrząc jednocześnie na <b>robaczka</b> powyżej.
              Utrzymuj nos na wysokości wspomnianej kropki.
              Jeśli <b>robaczek</b> zacznie się rozmazywać, naciśnij spację, następnie zacznij powoli oddalać głowę.
              W momencie gdy znów zobaczysz <b>robaczka</b> wyraźnie naciśnij spację.
            </div>
            <div className="toast-text-underlined mt-3">
              <small><b>Ćwiczenie powtórz {requiredCycles.current} razy</b></small>
            </div>
          </div>
          <Button onClick={startBeginningSequence} className={`btn btn-dark mt-2 w-100 ${(addedCameraPriviledges && initialMessageSecond) ? '' : 'disabled'}`}>Rozpocznij ćwiczenie</Button>
        </CustomToast>
        <CustomToast title="Ćwiczenie konwergencji" className="pb-5" toggle={finishExercise}>
          <div className="text-center">
              Ćwiczenie zostało zakończone
          </div>
          <Button onClick={() => { disableFullScreen(); }} className="btn btn-dark mt-3 w-100">Powrót do menu</Button>
        </CustomToast>
        <Fade in={initialMessageSecond || startExercise}>
          <div className="video-container">
            <video ref={video} className="debug-video" id="video"></video>
          </div>
        </Fade>
        <Fade in={initialMessageSecond || startExercise}>
          <div ref={textContainer} className="text-container-amplitude-accommodation">
            <img src={worm} className="text-amplitude-accommodation amplitude-accommodation-lefts" style={{height: `${parseInt(oneCmIsEqual.current.height)}px`, width: `${parseInt(oneCmIsEqual.current.height * 1.7)}px` }}></img>
            <div id="pointer" style={{ top: `calc(${parseInt(oneCmIsEqual.current.height * 2)}px - 0.5in)` }} className="pointer-amplitude-accommodation amplitude-accommodation-lefts"></div>
          </div>
        </Fade>
        <div className={`${(startExerciseForZoomIn && showAlertInfo) ? 'show' : 'fade'} convergence-zoom-popup`}>
          <Alert ref={zoomInfo} variant={'info'} >
            <Alert.Heading>Przybliż głowę do ekranu</Alert.Heading>
          </Alert>
        </div>
        <div className={`${(startExerciseForZoomOut && showAlertInfo) ? 'show' : 'fade'} convergence-zoom-popup`}>
          <Alert ref={zoomInfo} variant={'info'} >
            <Alert.Heading>Oddal głowę od ekranu</Alert.Heading>
          </Alert>
        </div>
        <Fade in={initialMessageSecond || startExercise} className="badge bg-dark convergence-counter-indicator">
          <div>
            <h1>{currentCycle.current - 1}</h1>
            <h5>POWTÓRZENIE</h5>
          </div>
        </Fade>
      </main>
    </>
  )
}
