import { useEffect, useRef, useState } from 'react';
import SecondaryButton from '@/Components/SecondaryButton';
import FaceDetectionEngine from "@/libs/faceDetectionEngine";
import Modal from '@/Components/Modal';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import calibrationDescription from "%/sounds/components/calibration_description.mp3";
import calibrationTitle from "%/sounds/components/calibration_title.mp3";

export default function CalibrationSettingsModal({ className = '', showModal, onModalClose, firstRender = false }) {
    const isMounted = useRef(false);
    const video = useRef(null);
    const faceDetection = useRef(null);
    const [startCalibration, setStartCalibration] = useState(showModal);
    const [focalLength, setFocalLength] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addedCameraPrivileges, setAddedCameraPrivileges] = useState(false);
    const calibrationDescriptionAudio = useRef(new Audio(calibrationDescription));
    const calibrationTitleAudio = useRef(new Audio(calibrationTitle));

    useEffect(() => {
        isMounted.current = true;
    }, []);

    const closeModal = () => {
        setStartCalibration(false);
        stopExercise();
        stopAudio();
        document.removeEventListener('keyup', spaceEventHandler);
        onModalClose();
    };

    const handleSubmit = () => {
        setLoading(true);
        setFocalLength(faceDetection.current.focalLength);
    };

    const openCalibrationModal = () => {
        setStartCalibration(true);
        runCamera();
    };

    useEffect(() => {
        console.log(isMounted.current, showModal);
        if (isMounted.current && showModal) {
            openCalibrationModal();
        }
    }, [showModal]);


    const stopExercise = () => {
        const tracks = video.current.srcObject?.getTracks() ?? [];
        tracks.forEach((track) => {
          track.stop();
        });
        video.current.srcObject = null;
    }

    const runCamera = async () => {

        await navigator.mediaDevices.getUserMedia({
            'audio': false,
            'video': {
                facingMode: 'user',
            }
            })
            .then(stream => {
                video.current.srcObject = stream;
            });

            video.current.oncanplay = async (e) => {

            calibrationTitleAudio.current.play();
            setTimeout(() => {
                if (focalLength === null) {
                    calibrationDescriptionAudio.current.play();
                }
                setAddedCameraPrivileges(true);
            }, 2000);

            faceDetection.current = new FaceDetectionEngine({
                video: video.current,
                callback: 'measureFocalLength'
            });
            await faceDetection.current.initializePrediction();
            await faceDetection.current.runPredictionLoop();

            video.current.play();
        };

        document.addEventListener('keyup', spaceEventHandler);
    }

    const spaceEventHandler = (e) => {
        if (e.code === 'Space') {
            handleSubmit();
        }
    }

    const stopAudio = () => {
        calibrationDescriptionAudio.current.currentTime = 0;
        calibrationDescriptionAudio.current.pause();
        calibrationTitleAudio.current.currentTime = 0;
        calibrationTitleAudio.current.pause();
    };

    useEffect(() => {
        if (isMounted.current && focalLength) {
            stopAudio();

            axios.patch(`/update-focal-length`, {
                'focal_length': focalLength
            })
                .then(res => {
                    setLoading(false);
                    closeModal();
                },
                (error) => {
                    console.log(error);
                    alert('Wystąpił błąd podczas zapisywania danych');
                    setLoading(false);
                    closeModal();
                });
            }
    }, [focalLength]);

    return (
        <section className={`space-y-6 ${className}`}>
            <Modal show={startCalibration} onClose={closeModal} closeable={false} backdrop="static">
                <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900">
                        Kalibracja odległości od kamery
                    </h2>
                    <div className="mt-1 text-sm text-gray-600">
                        <p>
                            Przy pomocy 40 centymetrowej linijki dokonaj kalibracji Twojej odległości od ekranu.
                            Przyłóż linijkę do zewnętrznej krawędzi oczodołu z jednej strony, natomiast z drugiej do soczewki kamery.
                        </p>
                        <p>Będąc w odległości 40 centymetrów od soczewki kamery, kliknij <b>Spację</b> na klawiaturze, bądź przycisk <b>ZAPISZ ODLEGŁOŚĆ</b>.</p>
                    </div>
                    <img className="man-at-a-laptop"></img>
                    <div className="mt-6 flex justify-end">
                        {!firstRender && <SecondaryButton onClick={closeModal}>Anuluj</SecondaryButton>}
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={loading}
                            disabled={!addedCameraPrivileges}
                            loadingIndicator="Zapisywanie"
                            variant="contained"
                            className="mx-3"
                            >Zapisz odległość
                        </LoadingButton>
                    </div>
                    <div className="video-container" style={{width: '0%'}}>
                        <video ref={video} id="video"></video>
                    </div>
                </div>
            </Modal>
        </section>
    );
}
