import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as faceMesh from '@mediapipe/face_mesh';

export default class FaceDetectionEngine {

    constructor({ video: video, focalLength: focalLength = null, callback: callback = () => {}, constDistanceDuringExercise = 40 }) {
        this.video = video;
        //this.container = container;
        this.irisWidthMm = 11.7;
        this.iris = {
            right: {
                left_point: 469,
                right_point: 471,
            },
            left: {
                left_point: 474,
                right_point: 476,
            },
        }
        this.focalLength = focalLength;
        this.measureError = 0.1;
        this.constError = 2.5;
        this.callback = callback;
        this.previousMeasurement = null;
        this.minimumMeasurement = null;
        this.arrayOfTheDistanceBetweenEyeCenters = [];
        this.constDistanceDuringExercise = constDistanceDuringExercise;
        this.faceDistanceArray = [];

        // this.canvas = document.getElementById("myCanvas");
        // this.canvas.width = this.video.videoWidth;
        // this.canvas.height = this.video.videoHeight;
        // this.ctx = this.canvas.getContext('2d');
    }

    async initializePrediction() {
        this.model = await faceLandmarksDetection.createDetector(
            faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh,
            {
                runtime: 'mediapipe',
                refineLandmarks: true,
                maxFaces: 1,
                solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
            }
        );
    }

    displayKeypoints(predictions) {
        this.ctx.strokeStyle = "red";
        if (predictions.length > 0) {
            predictions.forEach(prediction => {
                const keypoints = prediction.keypoints;
                for (let i = 0; i < keypoints.length; i++) {
                    const x = keypoints[i][0];
                    const y = keypoints[i][1];

                    this.ctx.beginPath();
                    this.ctx.arc(x, y, 2, 0, 2 * Math.PI);
                    this.ctx.fill();
                }
            });
        }
    }

    displayIrisPosition(predictions) {
        this.ctx.strokeStyle = "red";

        const keypoints = predictions.keypoints;
        if (keypoints.length == 478) {
            // for (let i = 4; i < 471; i++) {
            let x = keypoints[469].x;
            let y = keypoints[469].y;

            this.ctx.beginPath();
            this.ctx.rect(x, y, 2, 2);
            this.ctx.stroke();
            this.ctx.closePath();
            // }
        }

    }

    async runPredictionLoop() {
        await this.model.estimateFaces(this.video, { flipHorizontal: false })
            .then((result) => {
                result.every((prediction) => {
                    this[this.callback](prediction.keypoints);
                    // this.ctx.clearRect(0, 0, 1920, 1080);
                    // this.displayIrisPosition(prediction);
                    return false;
                })
            })

        window.requestAnimationFrame(this.runPredictionLoop.bind(this));
    }

    sendDistanceEvent(distanceToScreen) {
        //console.log(distanceToScreen, Math.abs(this.previousMeasurement - distanceToScreen), Math.abs(this.previousMeasurement * 0.3));

        if (this.minimumMeasurement === null) {
            this.minimumMeasurement = distanceToScreen;
        }
        else if (this.previousMeasurement !== null && Math.abs(this.previousMeasurement - distanceToScreen) > this.previousMeasurement * 1.7) {
            // console.log('prev', this.previousMeasurement, Math.abs(this.previousMeasurement - distanceToScreen));
            distanceToScreen = this.previousMeasurement;
        }
        else if (this.minimumMeasurement !== null && Math.abs(this.minimumMeasurement - distanceToScreen) > this.minimumMeasurement * 2) {
            // console.log('min', this.minimumMeasurement, Math.abs(this.minimumMeasurement - distanceToScreen));
            distanceToScreen = this.minimumMeasurement;
            this.previousMeasurement = this.minimumMeasurement;
        }
        else {
            this.previousMeasurement = null;
            this.minimumMeasurement = distanceToScreen;
        }

        this.distanceToScreen = distanceToScreen;
        // else if (Math.abs(this.previousMeasurement - distanceToScreen) > Math.abs(this.previousMeasurement * 0.5)) {
        //     console.log('diff');
        //     this.minimumMeasurement = this.previousMeasurement;
        //     this.previousMeasurement = distanceToScreen;
        //     distanceToScreen = this.minimumMeasurement;
        // }
        if (this.takeMeasurement) {
            const event = new Event('distance');
            event.key = 'distanceDiff';
            event.value = distanceToScreen;
            this.video.dispatchEvent(event);
            this.takeMeasurement = false;
        }
        //document.getElementById('text-to-read').textContent = distanceToScreen;
    }

    measureDistanceToScreen(keypoints) {
        this.sendDistanceEvent(this.getDistanceToScreen(this.getAverageDiameterOfEye(keypoints)));
    }

    detectLoosingFixation(keypoints) {

        this.measureDistanceToScreen(keypoints);

        const leftEyeEuclideanDistance = Math.sqrt((keypoints[263].x - keypoints[362].x) ** 2 + (keypoints[263].y - keypoints[362].y) ** 2);
        const rightEyeEuclideanDistance = Math.sqrt((keypoints[133].x - keypoints[33].x) ** 2 + (keypoints[133].y - keypoints[33].y) ** 2);
        const averageEyeEuclideanDistance = (leftEyeEuclideanDistance + rightEyeEuclideanDistance) / 2;

        const rightEyeToNoseEdgeEuclideanDistance = Math.sqrt((keypoints[133].x - keypoints[468].x) ** 2 + (keypoints[133].y - keypoints[468].y) ** 2);
        const rightEyeToEarEdgeEuclideanDistance = Math.sqrt((keypoints[33].x - keypoints[468].x) ** 2 + (keypoints[33].y - keypoints[468].y) ** 2);
        const leftEyeToNoseEdgeEuclideanDistance = Math.sqrt((keypoints[362].x - keypoints[473].x) ** 2 + (keypoints[362].y - keypoints[473].y) ** 2);
        const leftEyeToEarEdgeEuclideanDistance = Math.sqrt((keypoints[263].x - keypoints[473].x) ** 2 + (keypoints[263].y - keypoints[473].y) ** 2);

        const euclideanDistanceBeetwenCenterOfEyes = Math.sqrt((keypoints[476].x - keypoints[469].x) ** 2 + (keypoints[476].y - keypoints[469].y) ** 2);

        const averageIrisDiameter = this.getAverageDiameterOfEye(keypoints);
        if (this.arrayOfTheDistanceBetweenEyeCenters.length >= 50) {
            this.arrayOfTheDistanceBetweenEyeCenters.shift();
        }
        this.arrayOfTheDistanceBetweenEyeCenters.push(euclideanDistanceBeetwenCenterOfEyes/(1/this.distanceToScreen));
        // console.log(
        //     this.arrayOfTheDistanceBetweenEyeCenters.reduce((a,b) => a + b, 0) / this.arrayOfTheDistanceBetweenEyeCenters.length,
        // euclideanDistanceBeetwenCenterOfEyes/(1/this.distanceToScreen),
        // (rightEyeToNoseEdgeEuclideanDistance/rightEyeEuclideanDistance), (leftEyeToNoseEdgeEuclideanDistance/leftEyeEuclideanDistance),
        // Math.abs((rightEyeToNoseEdgeEuclideanDistance/rightEyeEuclideanDistance) - (leftEyeToEarEdgeEuclideanDistance/leftEyeEuclideanDistance)) > averageEyeEuclideanDistance * 0.1,
        // Math.abs((rightEyeToNoseEdgeEuclideanDistance + leftEyeToEarEdgeEuclideanDistance)/averageEyeEuclideanDistance),
        // Math.abs((rightEyeToEarEdgeEuclideanDistance + leftEyeToNoseEdgeEuclideanDistance)/averageEyeEuclideanDistance)

        // //leftEyeEuclideanDistance, rightEyeEuclideanDistance
        //         // averageIrisDiameter,
        //         // parseInt((leftEyeToNoseEdgeEuclideanDistance + rightEyeToEarEdgeEuclideanDistance + averageIrisDiameter)),
        //         // parseInt(averageEyeEuclideanDistance),
        // );

    //     ((rightEyeToNoseEdgeEuclideanDistance + leftEyeToEarEdgeEuclideanDistance + averageIrisDiameter)
    //     > (averageEyeEuclideanDistance * 1.10))
    // && ((leftEyeToNoseEdgeEuclideanDistance + rightEyeToEarEdgeEuclideanDistance + averageIrisDiameter)
    //     > (averageEyeEuclideanDistance * 1.10))
        const averageDistanceBetweenEyesCenter = this.arrayOfTheDistanceBetweenEyeCenters.reduce((a,b) => a + b, 0) / this.arrayOfTheDistanceBetweenEyeCenters.length;
        if (
           Math.abs(
            averageDistanceBetweenEyesCenter
               - euclideanDistanceBeetwenCenterOfEyes/(1/this.distanceToScreen)
            ) > averageDistanceBetweenEyesCenter * 0.15
        ) {
            let eye;

            if (rightEyeToNoseEdgeEuclideanDistance > leftEyeToNoseEdgeEuclideanDistance) {
                eye = 'right';
            } else {
                eye = 'left';
            }

            const event = new Event('losedFixation', {
                data: {
                    distance: this.distanceToScreen,
                    eye: eye
                }
            });

            this.video.dispatchEvent(event);
        }
    }

    measureDistanceToScreenForRightEye(keypoints) {
        this.sendDistanceEvent(this.getDistanceToScreen(this.getDiameterOfRightEye(keypoints)));
    }

    measureDistanceToScreenForLeftEye(keypoints) {
        this.sendDistanceEvent(this.getDistanceToScreen(this.getDiameterOfLeftEye(keypoints)));
    }

    calculateAverageOfArrayValues(array) {
        return array.reduce((a, b) => a + b, 0) / array.length;
    }

    keepConstantDistanceToScreen(keypoints) {
        const distanceToScreen = this.getDistanceToScreen(this.getAverageDiameterOfEye(keypoints));
        this.faceDistanceArray.push(distanceToScreen);

        if (this.faceDistanceArray.length > 20) {
            this.faceDistanceArray.shift();
        }

        const averageDistanceToScreen = this.calculateAverageOfArrayValues(this.faceDistanceArray);

        if ((this.constDistanceDuringExercise - (this.constDistanceDuringExercise * this.measureError)) - this.constError > averageDistanceToScreen) {
            const diff = parseInt((this.constDistanceDuringExercise - (this.constDistanceDuringExercise * this.measureError)) - this.constError - distanceToScreen);

            if (diff > 0) {
                const event = new Event('faceTooClose');
                event.key = 'distanceDiff';
                event.value = diff;
                this.video.dispatchEvent(event);
            }
        } else if (averageDistanceToScreen > (this.constDistanceDuringExercise * (1 + this.measureError) + this.constError)) {
            const diff = parseInt(distanceToScreen - (this.constDistanceDuringExercise * (1 + this.measureError) + this.constError));

            if (diff > 0) {
                const event = new Event('faceTooFar');
                event.key = 'distanceDiff';
                event.value = diff;
                this.video.dispatchEvent(event);
            }
        }
    }

    measureFocalLength(keypoints) {
        this.focalLength = this.getFocalLength(this.getAverageDiameterOfEye(keypoints));
    }

    async predictOneTime() {
        await this.model.estimateFaces(this.video, { flipHorizontal: false })
            .then((result) => {
                result.every((prediction) => {

                    if (this.focalLength === null) {
                        this.focalLength = this.getFocalLength(this.getAverageDiameterOfEye(prediction.keypoints));

                    }

                    //this.constDistanceDuringExercise = this.getDistanceToScreen(this.getAverageDiameterOfEye(prediction.keypoints));
                    return false;
                })
            })
    }

    setDistanceToScreen(distanceToScreen) {
        this.constDistanceDuringExercise = distanceToScreen;
    }

    getAverageDiameterOfEye(keypoints) {
        return Math.round(
            (
                (Math.sqrt(

                    (keypoints[this.iris.left.left_point].x - keypoints[this.iris.left.right_point].x) ** 2
                    + (keypoints[this.iris.left.left_point].y - keypoints[this.iris.left.right_point].y) ** 2)
                )
                +
                Math.sqrt(
                    (keypoints[this.iris.right.left_point].x - keypoints[this.iris.right.right_point].x) ** 2
                    + (keypoints[this.iris.right.left_point].y - keypoints[this.iris.right.right_point].y) ** 2
                )

            ) / 2
        );
    }

    getDiameterOfRightEye(keypoints) {
        //console.log(keypoints[this.iris.right.left_point].x, keypoints[this.iris.right.right_point].x);
        return Math.round(Math.sqrt
            ((keypoints[this.iris.right.left_point].x - keypoints[this.iris.right.right_point].x) ** 2
            + (keypoints[this.iris.right.left_point].y - keypoints[this.iris.right.right_point].y) ** 2)
        );
    }

    getDiameterOfLeftEye(keypoints) {
        //console.log(keypoints[this.iris.left.left_point].x, keypoints[this.iris.left.right_point].x);
        return Math.round(Math.sqrt(
            (keypoints[this.iris.left.left_point].x - keypoints[this.iris.left.right_point].x) ** 2
            + (keypoints[this.iris.left.left_point].y - keypoints[this.iris.left.right_point].y) ** 2)
        );
    }

    getDistanceToScreen(eyeDiameter) {
        return Math.round((this.irisWidthMm / eyeDiameter) * this.focalLength);
    }

    getFocalLength(eyeDiameter) {
        return Math.round((eyeDiameter * this.constDistanceDuringExercise) / this.irisWidthMm);
    }
}
