import * as tf from '@tensorflow/tfjs';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as faceMesh from '@mediapipe/face_mesh';
import styles from '../../styles/index.module.css'
import FaceGeometry from './face';


export default class Gaze {

    constructor() {
        const thatClass = this;
        this.leftEyes_x = [];
        this.rightEyes_x = [];
        this.eyeCorners_x = [];
        this.faceGeom = null;
        this.faceGeom_x = [];

        this.screenXYs_y = [];

        // real-time testing variable
        this.curEyes = [];
        this.prediction = null;

        // Stopping facemesh for training
        this.stopFacemesh = false;

        // Resize eyeballs to this size
        this.inx = 128;
        this.iny = 128;

        // eye image drawing zone
        this.ctx = null;
        this.canvas = null;
        // 2nd canvas for video stuff
        this.videoCanvas = null;
        this.videoCtx = null;

        // eye bounding boxes
        this.rBB = null;
        this.lBB = null;

        this.models = [];
        this.curPred = [-1, -1];
        this.curPred2 = [-1, -1];
        this.eyeCorners = [];

        this.videoDivisor = 2;

        this.natureModelEmbeddings = null;
        this.fmesh = null;

        this.svr_x = null;
        this.svr_y = null;

        this.videoWidth = null;
        this.videoHeight = null;

        this.regression = false;
        this.eyeHist = [];

        this.easX = 0;
        this.easY = 0;
    }

    makeModel() {

        const model = tf.sequential({
            layers: [
                tf.layers.conv2d({ inputShape: [this.iny, this.inx, 1], kernelSize: 3, filters: 8, activation: 'relu' }),
                //        tf.layers.maxPooling2d({poolSize: 2}),
                tf.layers.maxPooling2d({ poolSize: 3 }),
                tf.layers.batchNormalization(),
                tf.layers.flatten(),
                tf.layers.dense({ units: 20, activation: 'relu' }),
                tf.layers.dense({ units: 2 }),
            ]
        });

        model.summary();
        return model
    }


    boostedModel() {
        const model = tf.sequential({
            layers: [
                tf.layers.dense({ inputShape: [7], units: 100, activation: 'relu' }),
                tf.layers.dense({ units: 50, activation: 'relu' }),
                tf.layers.dense({ units: 2 })
            ]
        });

        model.summary();
        return model
    }

    natureModelFineTune(inElems) {
        const model = tf.sequential({
            layers: [
                //            tf.layers.dense({inputShape:[inElems], units:4, activation:'relu'}),
                //            tf.layers.batchNormalization(),
                //            tf.layers.dense({units:4, activation:'relu'}),
                //            tf.layers.batchNormalization(),
                //            tf.layers.dense({units:2}),
                tf.layers.dense({ inputShape: [inElems], units: 2 }),
            ]
        })

        model.summary();
        return model
    }


    // Save and load
    async saveModel(model) {
        await model.save('downloads://my-model');
    }


    async loadTFJSModel(path) {
        await tf.loadLayersModel(window.location.origin + path + "/my-model.json").then((model) => {
            this.models.push(model);
        });
    }

    // async continualCopy() {
    //     const tmpy = 50;
    //     this.eyectx.drawImage(this.canvas, 0, 0, this.inx, this.iny, (this.inx + this.betweenEyes), tmpy, this.inx, this.iny);
    //     this.eyectx.drawImage(this.canvas, this.inx + 10, 0, this.inx, this.iny, 0, tmpy, this.inx, this.iny);
    //     this.facectx.drawImage(this.videoCanvas, 0, 0, this.videoCanvas.width, this.videoCanvas.height,
    //         0, 0, this.faceCanvas.width, this.faceCanvas.height);

    //     this.facectx.fillStyle = "none";
    //     if (this.prediction.faceInViewConfidence > .8) {
    //         this.facectx.beginPath();
    //         // for (i of this.prediction.scaledMesh){
    //         //     facectx.beginPath();
    //         //     facectx.ellipse(i[0]*factor*672/720, i[1]*factor*896/960, 5, 5, 0,0,6.28);
    //         //     facectx.fill();
    //         // }
    //     }

    //     requestAnimationFrame(this.continualCopy.bind(this));
    // }

    // showDebug() {
    //     if (typeof (this.videoCanvas) == 'undefined') {
    //         console.log("showing debug failed since no this.videoCanvas, restarting");
    //         setTimeout(this.showDebug, 500);
    //         return;
    //     }

    //     setTimeout(() => {
    //         const dotelem = document.createElement("div");
    //         dotelem.setAttribute("class", "predicdot");
    //         dotelem.id = "dotelem";
    //         dotelem.style.zIndex = "400";
    //         this.regression = true;
    //         document.body.appendChild(dotelem);

    //         this.drawPrediction();

    //         console.log("showing debug");
    //         //document.body.style.backgroundColor = "white";

    //         // Draw head image onto screen
    //         this.faceCanvas = document.createElement("canvas");
    //         this.faceCanvas.width = 800;
    //         this.faceCanvas.height = 480;//960/720*faceCanvas.width;
    //         this.faceCanvas.style.position = 'absolute';
    //         this.faceCanvas.style.top = 200 + "px";
    //         this.faceCanvas.style.left = (window.innerWidth - this.faceCanvas.width) / 2 + "px";
    //         this.faceCanvas.style.transform = "scaleX(-1)";
    //         this.facectx = this.faceCanvas.getContext("2d");
    //         document.body.append(this.faceCanvas);

    //         // Draw eye image onto screen
    //         const factor = 2;
    //         this.betweenEyes = 80
    //         this.eyeCanvas = document.createElement("canvas");
    //         this.eyeCanvas.width = (factor) * this.inx * 2 + this.betweenEyes * factor;
    //         this.eyeCanvas.height = factor * 2 * this.iny + 10;
    //         this.eyeCanvas.style.position = 'absolute';
    //         this.eyeCanvas.style.left = (window.innerWidth - this.eyeCanvas.width) / 2 + "px";
    //         this.eyeCanvas.style.top = 0 + "px";
    //         this.eyeCanvas.style.transform = "scaleX(-1)";

    //         this.eyectx = this.eyeCanvas.getContext("2d");
    //         document.body.append(this.eyeCanvas);

    //         this.continualCopy();

    //         const accelDisplay = document.createElement("p");
    //         accelDisplay.id = "curOrientation";
    //         accelDisplay.style.position = "absolute";
    //         accelDisplay.style.top = Math.trunc(window.innerHeight / 5.5) + "px";
    //         accelDisplay.style.left = "50px";
    //         accelDisplay.style.fontSize = "2em";
    //         // document.body.append(accelDisplay);
    //         console.log("showing debug");

    //         // Test Target
    //         const tt = document.createElement("div");
    //         tt.style.position = "absolute";
    //         tt.style.width = "500px";
    //         tt.style.height = "265px";
    //         tt.style.top = "1650px";
    //         tt.style.left = Math.trunc(window.innerWidth / 2 - 500 / 2) + "px";
    //         tt.style.backgroundColor = "pink";

    //         tt.innerHTML = "Test Target";
    //         tt.style.textAlign = "center";
    //         tt.style.fontSize = "3em";
    //         tt.style.lineHeight = "100px";
    //         //        tt.style.fontSize = "3em";

    //         document.body.append(tt);


    //         // Text displays
    //         // markovNodes = [];
    //         // for (i of [1,2,3]){
    //         //     markovNodes.push(makeTextDisplay());
    //         // }


    //         setInterval(() => {
    //             // Update eye history
    //             this.eyeHist.push([...this.curPred]);
    //             if (this.eyeHist.length > 25) this.eyeHist.shift();
    //             const xs = this.eyeHist.map((e) => e[0]);
    //             const ys = this.eyeHist.map((e) => e[1]);

    //             let truthArr = [this.prediction.faceInViewConfidence > .9,
    //             window.abs(this.faceGeom.curYaw) < .3,
    //             ((Math.max(...xs) - Math.min(...xs)) < .3) && ((Math.max(...ys) - Math.min(...ys)) < .3)];

    //             const successText = ["1. User Present: True",
    //                 "<br>2. Looking At Screen: True",
    //                 "<br><br>3. Fixated On Target: True<br>4. Motion Gestures: Running"]
    //             const failText = ["1. User Present: False",
    //                 "<br>2. Looking At Screen: False",
    //                 "<br><br>3. Fixated On Target: False<br>4. Motion Gestures: Paused"]

    //             // for (i of [0,1,2]){
    //             //     if (truthArr.slice(0,i+1).every((x) => x)){ // If true up to here,
    //             //         markovNodes[i].style.color = 'none';
    //             //         markovNodes[i].innerHTML = successText[i];
    //             //     } else{
    //             //         markovNodes[i].style.color = 'none';
    //             //         markovNodes[i].innerHTML = failText[i];
    //             //     }
    //             // }


    //             // Hide dot if not all proper
    //             // elem = document.getElementById("dotelem");
    //             // if (truthArr.every((x) => x)){
    //             //     elem.hidden = false;
    //             //     elem.style.backgroundColor = "green";
    //             //     tt.style.backgroundColor = "lightgreen";
    //             // } else if (truthArr.slice(0,2).every((x) => x)){
    //             //     elem.hidden = false;
    //             //     elem.style.backgroundColor = "red";
    //             //     tt.style.backgroundColor = "pink";
    //             // } else {
    //             //     elem.hidden = true;
    //             //     tt.style.backgroundColor = "pink";
    //             // }

    //         }, 50);

    //     }, 1000);
    // }

    async runSVRlive() {

        if (this.curEyes === [] && !this.stopFacemesh) {
            console.log("this.curEyes undefined while running this.prediction, trying again")
            setTimeout(this.runSVRlive, 500);
            return
        }

        if (this.lBB !== null) {
            try {
                const rawPred = tf.tidy(() => {
                    //console.log(this.faceGeom.getGeom(), this.natureModelEmbeddings);
                    let curGeom = tf.tensor(this.faceGeom.getGeom()).reshape([1, 4]);
                    let embed = this.natureModelEmbeddings.predict([this.curEyes[0].div(256).sub(0.5).reshape([1, 128, 128, 3]), this.curEyes[1].div(256).sub(0.5).reshape([1, 128, 128, 3]), this.curEyes[2].reshape([1, 8]), curGeom]);
                    embed[0] = embed[0].div(100);
                    embed[1] = embed[1].div(10);
                    embed = tf.concat(embed, 1);

                    const allFeatures = tf.concat([embed, this.curEyes[2].reshape([1, 8]), curGeom], 1);
                    const allFeatures_mat = window.array2mat(allFeatures.arraySync());

                    return [this.svr_x.predict(allFeatures_mat), this.svr_y.predict(allFeatures_mat)]
                })

                let a = 0.5;
                this.curPred[0] = this.curPred[0] * (1 - a) + rawPred[0] * a;
                this.curPred[1] = this.curPred[1] * (1 - a) + rawPred[1] * a;

                let edge = 0.01;
                this.curPred[0] = Math.max(edge, Math.min(1 - edge, this.curPred[0]))
                this.curPred[1] = Math.max(edge, Math.min(1 - edge, this.curPred[1]))
            } catch {
                //do nothing
            }
        }

        requestAnimationFrame(this.runSVRlive.bind(this));

    }

    async eyeSelfie(continuous) {
        // Wait to start if this.rBB not defined
        const that = this;
        if (this.rBB == undefined && !this.stopFacemesh) {
            console.log("this.rBB undefined in eyeSelfie, trying again")
            setTimeout(function () { that.eyeSelfie(continuous) }, 500);
            return
        }

        // Draw from video onto this.canvas BEFORE you try to clip it out of the this.canvas
        //    drawCache(continuous);

        // If running continuously, update the this.curEyes and curCorners vec AS TENSORS, BUT THROW AWAY OLD VALS
        if (continuous) {
            if (this.curEyes.length == 3) {
                this.curEyes[0].dispose();
                this.curEyes[1].dispose();
                this.curEyes[2].dispose();
            }

            this.curEyes = tf.tidy(() => {
                return [tf.browser.fromPixels(
                    this.ctx.getImageData(0, 0, this.inx, this.iny)).reverse(1),
                tf.browser.fromPixels(
                    this.ctx.getImageData(10 + this.inx, 0, this.inx, this.iny)),
                tf.tensor(this.eyeCorners)]
            })

        } else { // If calling once, push the eyes, corners, and screenVals into a vector AS TENSORS
            // Add x vars
            let left = tf.tidy(() => {
                return tf.browser.fromPixels(
                    this.ctx.getImageData(0, 0, this.inx, this.iny)).reverse(1)
            })
            let right = tf.tidy(() => {
                return tf.browser.fromPixels(
                    this.ctx.getImageData(10 + this.inx, 0, this.inx, this.iny))
            })
            let tmpEyeCorn = tf.tensor(this.eyeCorners);

            this.leftEyes_x.push(left);
            this.rightEyes_x.push(right);
            this.eyeCorners_x.push(tmpEyeCorn);
            this.faceGeom_x.push(this.faceGeom.getGeom());

            // Add y vars
            //const nowVals = [X/windowWidth, Y/windowHeight];
            //this.screenXYs_y.push(nowVals);
            // const nowPos = calib_counter-1; // This var is for classification. -1 To start at zero
        }


        if (continuous) {
            requestAnimationFrame(() => { that.eyeSelfie(continuous) });
        }
    }

    async setupCamera() {
        const that = this;
        this.video = document.getElementById('video');

        this.video.oncanplay = async (e) => {
            this.drawCache();
        };
    }

    async collectmain() {
        this.fmesh = await faceLandmarksDetection.createDetector('MediaPipeFaceMesh', {
            runtime: 'mediapipe',
            refineLandmarks: true,
            maxFaces: 1,
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@${faceMesh.VERSION}`,
        });

        // Set up front-facing camera
        await this.setupCamera();
        //this.video.play();
        this.videoWidth = this.video.videoWidth;
        this.videoHeight = this.video.videoHeight;
        this.faceGeom = new FaceGeometry(this.video);

        // Set up this.canvas to draw the eyes of the user
        this.canvas = document.getElementById('eyecache');
        this.canvas.width = 300;
        this.canvas.height = 200;
        this.ctx = this.canvas.getContext('2d');

        // Set up second this.canvas to draw the this.video stream at a reduced size
        this.videoCanvas = document.createElement("canvas");
        this.videoCanvas.setAttribute("id", "facecanvas");
        this.videoCanvas.setAttribute("hidden", "true");
        document.body.appendChild(this.videoCanvas);

        this.videoCanvas.width = this.videoWidth / this.videoDivisor;
        this.videoCanvas.height = this.videoHeight / this.videoDivisor;
        this.videoCtx = this.videoCanvas.getContext('2d');
        this.renderPrediction()
        console.log("Real-time camera setup is complete");
    }

    getEyeCorners(eyePred, h, w) {
        let mesh = eyePred.keypoints;
        const left_leftcorner = mesh[263];
        const left_rightcorner = mesh[362];
        const right_rightcorner = mesh[33];
        const right_leftcorner = mesh[133];

        return [left_leftcorner.x / w, left_leftcorner.y / h, left_rightcorner.x / w, left_rightcorner.y / h,
        right_rightcorner.x / w, right_rightcorner.y / h, right_leftcorner.x / w, right_leftcorner.y / h]
    }

    eyeBoundsFromCorners(leftCorner, rightCorner) {
        let eyeLen = leftCorner[0] - rightCorner[0]
        let xshift = eyeLen / 5
        eyeLen += 2 * xshift;
        const yshift = eyeLen / 2
        let yref = (leftCorner[1] + rightCorner[1]) / 2

        let tmp = [rightCorner[0] - xshift, leftCorner[0] + xshift, yref - yshift, yref + yshift]
        tmp.push(tmp[1] - tmp[0]); // Add width
        tmp.push(tmp[3] - tmp[2]); // Add height

        tmp.forEach((elem, ind) => {
            tmp[ind] = elem * this.videoDivisor;
        });

        return tmp
    } // return [left, right, top, bottom, width, height]

    // Calls face mesh on the this.video and outputs the eyes and face bounding boxes to global vars
    async renderPrediction() {
        //const now = performance.now();
        //    const facepred = await this.fmesh.estimateFaces(this.video);
        const facepred = await this.fmesh.estimateFaces(this.videoCanvas);

        if (facepred.length > 0) {
            if (typeof this.gazePointer !== 'undefined') {
                this.gazePointer.removeAttribute("hidden");
            }
            // If we find a face, proceed with first and only this.prediction
            this.prediction = facepred[0];

            // find eye corners
            this.eyeCorners = this.getEyeCorners(this.prediction, this.videoCanvas.height, this.videoCanvas.width)

            // Get eye bounding boxes from eye corners
            const h = this.videoCanvas.height
            const w = this.videoCanvas.width
            this.lBB = this.eyeBoundsFromCorners([this.eyeCorners[0] * w, this.eyeCorners[1] * h], [this.eyeCorners[2] * w, this.eyeCorners[3] * h]);
            this.rBB = this.eyeBoundsFromCorners([this.eyeCorners[6] * w, this.eyeCorners[7] * h], [this.eyeCorners[4] * w, this.eyeCorners[5] * h]);

            // Get face geometry
            this.faceGeom.update(this.prediction);
        } else {
            this.lBB = this.rBB = null;

            if (typeof this.gazePointer !== 'undefined') {
                this.gazePointer.setAttribute("hidden", "true");
            }
        }

        this.drawCache();

        if (!this.stopFacemesh) {
            setTimeout(requestAnimationFrame(this.renderPrediction.bind(this)), 100); // call self after 100 ms
        }
    };

    // Draws the current eyes onto the canvas, directly from this.video streams
    async drawCache() {
        // Draw face onto reduced canvas
        this.videoCtx.drawImage(this.video, 0, 0, this.videoCanvas.width, this.videoCanvas.height);

        // Get eye images from the this.video stream directly
        if (this.lBB !== null) {
            this.ctx.drawImage(this.video, this.lBB[0], this.lBB[2], this.lBB[4], this.lBB[5], // Source x,y,w,h
                0, 0, this.inx, this.iny); // Destination x,y,w,h
            this.ctx.drawImage(this.video, this.rBB[0], this.rBB[2], this.rBB[4], this.rBB[5],
                10 + this.inx, 0, this.inx, this.iny);
        }
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }

    async drawPrediction() {
        const that = this;
        if (typeof (this.curPred) == "undefined") {
            setTimeout(that.drawPrediction, 300);
            return;
        }

        //Generate this.prediction dot
        const predX = Math.min(Math.max(Math.floor(this.curPred[0] * 110), 0), 100);
        const predY = Math.min(Math.max(Math.floor(this.curPred[1] * 130), 0), 100);

        this.easX = this.easX + (predX - this.easX) * 0.3;
        this.easY = this.easY + (predY - this.easY) * 0.3;

        const pointer = document.getElementById('pointer');
        const pointerBounds = pointer.getBoundingClientRect();
        const pointerCenterWidth = (parseInt(pointerBounds.left) + parseInt(pointerBounds.width)/2).toFixed(2);
        const pointerCenterHeight = (parseInt(pointerBounds.top) + parseInt(pointerBounds.height)/2).toFixed(2);
        const percentageWidth = ((pointerCenterWidth / window.screen.width) * 100).toFixed(2);
        const percentageHeight = ((pointerCenterHeight / window.screen.height) * 100).toFixed(2);

        const wMinus = this.easX - percentageWidth;
        const wPlus = percentageWidth - this.easX;
        const hMinus = this.easY - percentageHeight;
        const hPlus = percentageHeight - this.easY;
        const rnd = this.getRandomArbitrary(0.7, 1.3);

        if ((percentageWidth >= this.easX) && (wPlus < 12) && (percentageHeight >= this.easY) && (hPlus < 12)) {
            this.easX += (wPlus  * rnd);
            this.easY += (hPlus * rnd);
        } else if ((percentageWidth >= this.easX) && (wPlus < 12) && (percentageHeight <= this.easY) && (hMinus < 12)) {
            this.easX += (wPlus * rnd);
            this.easY -= (hMinus  * rnd);
        } else if ((percentageWidth <= this.easX) && (wMinus < 12) && (percentageHeight >= this.easY) && (hPlus < 12)) {
            this.easX -= (wMinus * rnd);
            this.easY += (hPlus * rnd);
        } else if ((percentageWidth <= this.easX) && (wMinus < 12) && (percentageHeight <= this.easY) && (hMinus < 12)) {
            this.easX -= (wMinus  * rnd);
            this.easY -= (hMinus  * rnd);
        }

        this.gazePointer.style.left = "calc(" + this.easX + "% - 0.1in)";
        this.gazePointer.style.top = "calc(" + this.easY  + "% - 0.1in)";

        requestAnimationFrame(this.drawPrediction.bind(this));
    }

    async main() {
        tf.setBackend('webgl');
        await tf.ready();
        await this.collectmain();

        // import custom model
        this.models = [];
        console.log("Loading base model");
        await this.loadTFJSModel("/models/tfjsmodel4");
        const naturemodel = this.models[0];

        // Set up embeddings output
        this.natureModelEmbeddings = tf.model({
            inputs: naturemodel.inputs,
            outputs: [naturemodel.layers[39].output, naturemodel.layers[43].output, naturemodel.layers[46].output]
        });

        // Load in SVR
        console.log("Loading calibration model");
        const svr_x_str = localStorage.getItem("svr_x");
        const svr_y_str = localStorage.getItem("svr_y");
        this.svr_x = window.renewObject(JSON.parse(svr_x_str));
        this.svr_y = window.renewObject(JSON.parse(svr_y_str));

        // If there is no SVR, load in the default one and warn user
        if (svr_x_str == null || svr_y_str == null) {
            console.log("WARNING: Loading default SVR, expect sub-par gaze tracking")
            let defaultSVR_str = await fetch("/models/defaultsvr.txt")
                .then(response => response.text())
                .then(data => data);

            const defaultSVRs = JSON.parse(JSON.parse(defaultSVR_str));

            this.svr_x = window.renewObject(defaultSVRs[0])
            this.svr_y = window.renewObject(defaultSVRs[1])
        }

        // start the live loop
        const that = this;
        setTimeout(function () {
           that.eyeSelfie(true);
        }, 10);

        setTimeout(this.runSVRlive.bind(this), 20);

        console.log("Gaze this.prediction setup complete");

        this.gazePointer = document.createElement("div");
        this.gazePointer.setAttribute("class", "predicdot");
        this.gazePointer.id = 'gaze-pointer';
        this.regression = true;
        document.body.appendChild(this.gazePointer);

        this.drawPrediction();

        const event = new Event('gazeLaunched');
        document.body.dispatchEvent(event);
        //this.showDebug();
    }
}