import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { createTheme, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  TableBody,
  Table,
  TableHead,
  Paper,
  TableRow,
  Rating,
} from '@mui/material';

import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Delete, Edit } from '@mui/icons-material';
import { useEffect } from 'react';
import { router } from '@inertiajs/react';
import axios from 'axios';
import ExerciseSettings from './ExerciseSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ExerciseProperties from './ExerciseProperties';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CreateNewAccountModal from './CreateNewAccountModal';

dayjs.extend(utc);
dayjs.extend(timezone);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const buttonTheme = createTheme({
  palette: {
    dark: {
      main: '#212529',
      contrastText: '#47008F',
    },
  },
});

const UserListDataTable = (props) => {

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editAccount, setEditAccount] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(false);
  const [addNextLevelToUser, setAddNextLevelToUser] = useState(false);
  const [tableData, setTableData] = useState(() => props.data);
  const [validationErrors, setValidationErrors] = useState({});
  const [isTester, setIsTester] = useState(() => Boolean(props.user.roles.filter((obj) => obj.name === 'tester').length));

  const handleEditRow = (values) => {
    axios.patch('/admin/users', values, {
      onSuccess: () => {
        tableData.push(values);
        console.log(tableData);
        setTableData([...tableData]);
      },
      onError: () => {
        alert('Wystąpił błąd podczas zapisywania danych');
      }
    });
  };

  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleDeleteRow = (row) => {
    if (
      !confirm(`Czy chcesz usunąć użytkownika ${row.original.full_name}`)
    ) {
      return;
    }

    axios.delete(`/admin/users/${row.original.id}`)
      .then(res => {
        if (res.status === 200) {
          router.reload();
        }
      }, (error) => {
        alert('Wystąpił błąd podczas usuwania użytkownika');
      });
  };

  const handleDeleteExercisesStage = (row) => {
    if (
      !confirm(`Czy chcesz usunąć poziom ${row.stage}`)
    ) {
      return;
    }

    axios.delete(`/admin/exercises-stage/${row.id}`)
      .then(res => {
        if (res.status === 200) {
          router.reload();
        }
      }, (error) => {
        alert('Wystąpił błąd podczas usuwania użytkownika');
      });
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
                ? validateAge(+event.target.value)
                : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Imię i nazwisko',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email',
        }),
      },
      {
        accessorKey: 'latest_exercises_stage.stage',
        header: 'Aktualny poziom',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'latest_activity',
        header: 'Data ostatniej aktywności',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
          'mrt-row-actions': {
            header: 'Czynności',
          },
        }}
        columns={columns}
        data={tableData}
        enableRowNumbers
        rowNumberMode="original"
        enableColumnOrdering
        enableStickyHeader
        renderDetailPanel={({ row }) => (
          <Box sx={{ m: 1, mt: 0 }} key={row.id}>
            <Typography variant="h5" align="center" gutterBottom component="div"
              className="bg-white shadow-lg sm:rounded-lg"
              sx={{
                p: 1,
                mb: 2,
                border: '1px solid #BBBCBD'
              }}>Wyniki ćwiczeń
            </Typography>
            { row.original.exercises_stages.length === 0
              ? <button style={{padding: '5px', paddingBottom: '3px'}} className="badge bg-primary ms-1" onClick={() => { setAddNextLevelToUser(row.original.id)}}><AddBoxIcon></AddBoxIcon> Dodaj poziom</button>
              : row.original.exercises_stages.map((stage) =>
              <>
                <Typography variant="h6" gutterBottom component="div" sx={{ mt: 2}}>
                  <span className="badge bg-dark p-2">Poziom {stage.stage}</span>
                  {!isTester && row.original.latest_exercises_stage.stage == stage.stage
                    ? <>
                        <button style={{padding: '5px', paddingBottom: '3px'}} className="badge bg-primary ms-1" onClick={() => { setAddNextLevelToUser(row.original.id)}}><AddBoxIcon></AddBoxIcon> Dodaj poziom</button>
                        <button style={{padding: '5px', paddingBottom: '3px'}} className="badge bg-danger ms-1" onClick={() => { handleDeleteExercisesStage(stage)}}><DeleteIcon></DeleteIcon> Usuń poziom</button>
                      </>
                    : ''
                  }
                </Typography>
                <Table size="small" aria-label="exercises">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Nazwa ćwiczenia</b></TableCell>
                      <TableCell align="center"><b>Liczba wykonanych ćwiczeń</b></TableCell>
                      <TableCell align="center"><b>Wynik</b></TableCell>
                      {!isTester && <TableCell align="center"><b>Ustawienia</b></TableCell>}
                      <TableCell align="right"><b>Szczegółowe wyniki</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stage.exercises.map((exercise) =>
                      <TableRow key={exercise.id}>
                        <TableCell component="th" scope="row">
                          {exercise.name}
                        </TableCell>
                        <TableCell align="center">
                          {exercise.exercises_count} / {exercise.number_of_iterations}
                        </TableCell>
                        <TableCell align="center">
                          <Rating name="read-only" size="large" value={exercise.rating ?? 0} precision={0.5} readOnly />
                        </TableCell>
                        {!isTester &&
                          <TableCell align="center">
                            <Button theme={buttonTheme} className="btn btn-outline-dark" onClick={() => setExerciseToEdit(exercise)}>
                              <SettingsApplicationsIcon style={{ position: 'relative', marginRight: '5px', top: '-1px' }} /><span>Ustawienia</span>
                            </Button>
                          </TableCell>
                        }
                        <TableCell align="right">
                          <Button theme={buttonTheme} className="btn btn-outline-dark" onClick={() => router.visit(`/admin/exercises/history/${exercise.id}`)}>
                            <ReadMoreIcon style={{ position: 'relative', marginRight: '5px', top: '-1px' }} /><span>Wyniki</span>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edytuj">
              <IconButton onClick={() => setEditAccount(row.original)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {!isTester && <Tooltip arrow placement="right" title="Usuń">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>}
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          !isTester && <Button
            color="primary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Dodaj nowego użytkownika
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <AddNextLevel
        user={addNextLevelToUser}
        onClose={() => setAddNextLevelToUser(false)}
      />
      <EditAccountModal
        account={editAccount}
        onClose={() => setEditAccount(false)}
        onSubmit={handleEditRow}
      />
      <EditSettings
        exercise={exerciseToEdit}
        onClose={() => setExerciseToEdit(false)}
        exerciseProperties={props.exerciseProperties}
      />
    </>
  );
};

export const EditSettings = ({exercise, onClose, exerciseProperties}) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const updateProperties = (data) => {
    console.log(data, values);
    setValues({...values, ...data});
  };

  const handleSubmit = () => {
    console.log(values);
    setLoading(true);

    axios.patch(`/admin/exercises/${exercise.id}`, values)
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          onClose();
          router.reload();
        } else {
          setLoading(false);
          onClose();
        }
      }, (error) => {
        console.log(error);
        alert('Wystąpił błąd podczas zapisywania danych');
        setLoading(false);
        onClose();
      });

      setValues({});
  };

  return (
    <Dialog open={!!exercise} maxWidth='xl' sx={{ minWidth: '750px' }}>
      <DialogTitle textAlign="center"
        sx={{
          background: '#00000012',
          borderRadius: 1,
          color: '#6E6E6E',
          borderBottom: '1px solid #BBBCBD',
          p: 1,
          mb: 2
        }}>Ustawienia ćwiczenia</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ExerciseSettings title={exercise.name} name={exercise.table_name} exercise={exercise} setValues={(data) => updateProperties(data)}>
                {exercise && Object.entries(exercise?.properties).filter(([key, val]) => typeof exerciseProperties[exercise.table_name][key] !== 'undefined').map(([key, val]) =>
                  <ExerciseProperties
                    title={exerciseProperties[exercise.table_name][key]['title']}
                    min={exerciseProperties[exercise.table_name][key]['min'] ?? null}
                    max={exerciseProperties[exercise.table_name][key]['max']}
                    step={exerciseProperties[exercise.table_name][key]['step'] ?? null}
                    tableName={exercise.table_name}
                    propertiesKey={key} propertiesValue={val}
                    setValues={(data) => updateProperties(data)}
                  ></ExerciseProperties>
                )}
              </ExerciseSettings>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { setValues({}); onClose(); }}>Anuluj</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator="Zapisywanie…"
          variant="contained"
        >Zapisz</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const AddNextLevel = ({user, onClose}) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const updateProperties = (data) => {
    console.log(data, values);
    setValues({...values, ...data});
  };

  const handleSubmit = () => {
    console.log(values);
    setLoading(true);

    axios.post(`/admin/users/${user}/add-stage`, values)
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          onClose();
          router.reload();
        } else {
          setLoading(false);
          onClose();
        }
      }, (error) => {
        console.log(error);
        alert('Wystąpił błąd podczas zapisywania danych');
        setLoading(false);
        onClose();
      });

      setValues({});
  };

  return (
    <Dialog open={!!user} maxWidth='xl' sx={{ minWidth: '750px' }}>
      <DialogTitle textAlign="center"
        sx={{
          background: '#00000012',
          borderRadius: 1,
          color: '#6E6E6E',
          borderBottom: '1px solid #BBBCBD',
          p: 1,
          mb: 2
        }}>Dodaj kolejny poziom</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ExerciseSettings title="Sakkadowe Ruchy Oczu" name="saccadic_eye_movements" setValues={(data) => updateProperties(data)}>
              </ExerciseSettings>
              <ExerciseSettings title="Wodzące Ruchy Oczu" name="leading_eye_movements" setValues={(data) => updateProperties(data)}></ExerciseSettings>
              <ExerciseSettings title="Ćwiczenie sprawności akomodacji" name="eye_accommodation_efficiencies" setValues={(data) => updateProperties(data)}></ExerciseSettings>
              <ExerciseSettings title="Ćwiczenie konwergencji" name="convergence_of_eyes" setValues={(data) => updateProperties(data)}></ExerciseSettings>
              <ExerciseSettings title="Ćwiczenie amplitudy akomodacji" name="amplitude_of_eye_accommodations" setValues={(data) => updateProperties(data)}></ExerciseSettings>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { setValues({}); onClose(); }}>Anuluj</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator="Zapisywanie…"
          variant="contained"
        >Zapisz</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};


export const EditAccountModal = ({ onClose, onSubmit, account }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  const handleSubmit = () => {
    console.log(values);
    setErrors({});
    if (checkValidation()) {
      setLoading(true);
      axios.patch('/admin/users', values)
        .then(res => {
          if (res.status === 200) {
            setLoading(false);
            onClose();
            router.reload();
          } else {
            setLoading(false);
            onClose();
          }
        }, (error) => {
          alert('Wystąpił błąd podczas zapisywania danych');
          setLoading(false);
          onClose();
        });

        setValues({});
    }
  };

  const checkValidation = () => {
    if (!!!values.first_name?.length) {
      setErrors({ ...errors, first_name: 'Wprowadź imię użytkownika'});
      return false;
    }
    if (!!!values.last_name?.length) {
      setErrors({ ...errors, last_name: 'Wprowadź nazwisko użytkownika'});
      return false;
    }
    if (!!!values.born_date?.length) {
      setErrors({ ...errors, born_date: 'Wprowadź datę urodzenia użytkownika'});
      return false;
    }

    return true;
  }

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setValues({ ...values, first_name: account.first_name, last_name: account.last_name, born_date: account.born_date, user_id: account.id });
    }
  }, [account])

  return (
    <Dialog open={!!account}>
      <DialogTitle textAlign="center">Edytuj dane użytkownika {account?.full_name}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1rem',
              mt: 1
            }}
          >
            <TextField
              key="first_name"
              label="Imię"
              name="first_name"
              value={values.first_name ?? account.first_name}
              required
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              size="small"
            />
            <TextField
              key="last_name"
              label="Nazwisko"
              value={values.last_name ?? account.last_name}
              name="last_name"
              required
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              size="small"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={dayjs(values.born_date ?? account.born_date)}
                    required
                    label="Data urodzenia"
                    timezone="Europe/Warsaw"
                    openTo="year"
                    format="YYYY-MM-DD"
                    onChange={(data) => setValues({ ...values, ['born_date']: dayjs(data).format('YYYY-MM-DD') })}
                    helperText={errors.born_date}
                />
            </LocalizationProvider>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { setValues({}); onClose(); }}>Anuluj</Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={loading}
            loadingIndicator="Zapisywanie"
            variant="contained"
          >Zapisz</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

const propertiesHandel = {

}
export default UserListDataTable;