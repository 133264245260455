import { CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Card } from "react-bootstrap";
import CustomNumberInput from "./CustomNumberInput";
import { useEffect, useState } from "react";
import { useRef } from "react";

const ExerciseSettings = (props) => {
    const [exerciseValues, setExerciseValues] = useState({ [`${props.name}_delay`]: props.exercise?.delay_to_launch_exercise ?? 120, [`${props.name}_iterations`]: props.exercise?.number_of_iterations ?? 10 });
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            props.setValues(exerciseValues);
        }
    }, [exerciseValues]);

    return <Card className="mb-3" sx={{
        gap: '1rem',
        borderRadius: 1,
        border: '1px solid #BBBCBD',
        mb: 3
      }}>
        <CardHeader sx={{
          background: '#00000012',
          borderRadius: 1,
          color: '#6E6E6E',
          borderBottom: '1px solid #BBBCBD',
          p: 1
        }} title={<Typography variant="body1" align="center">{props.title}</Typography>}></CardHeader>
        <CardContent sx={{ p: 1, pb: 1 }} className="pb-1">
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={6} sx={{ m: 'auto' }}>
              <span>Czas opóźnienia [min]</span>
            </Grid>
            <Grid item xs={6} sx={{ m: 'auto' }}>
              <CustomNumberInput min={0} max={1440} name={`${props.name}_delay`} value={exerciseValues[`${props.name}_delay`]}
                onChange={(e) => {setExerciseValues({ ...exerciseValues, [`${props.name}_delay`]: parseInt(e.target.value) }); console.log(props.values);}}>
              </CustomNumberInput>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={6} sx={{ m: 'auto' }}>
              <span>Liczba iteracji</span>
            </Grid>
            <Grid item xs={6}>
              <CustomNumberInput min={0} max={100} name={`${props.name}_iterations`} value={exerciseValues[`${props.name}_iterations`]}
                onChange={(e) => props.setValues({ ...exerciseValues, [`${props.name}_iterations`]: parseInt(e.target.value) })}>
              </CustomNumberInput>
            </Grid>
          </Grid>
            {props.children}
        </CardContent>
      </Card>
}

export default ExerciseSettings;