import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import { Link, useForm, usePage } from "@inertiajs/react";
import { Transition } from "@headlessui/react";
import DecimalInput from "@/Components/DecimalInput";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function UpdateProfileInformation({
    mustVerifyEmail,
    status,
    className = "",
}) {
    const user = usePage().props.auth.user;

    const { data, setData, patch, errors, processing, recentlySuccessful } =
        useForm({
            first_name: user.first_name,
            last_name: user.last_name,
            born_date: dayjs(user.born_date ?? '1990-01-01'),
            screen_inch: user.screen_inch,
            email: user.email,
        });

    const submit = (e) => {
        e.preventDefault();
        if (checkValidation) {
            patch(route("profile.update"));
        }
    };

    const checkValidation = () => {
        if (!!!values.first_name?.length) {
          setError('first_name', {
            type: 'custom',
            message: 'Wprowadź imię użytkownika'
          });
          return false;
        }
        if (!!!values.last_name?.length) {
            setError('last_name', {
                type: 'custom',
                message: 'Wprowadź nazwisko użytkownika'
            });
          return false;
        }
        if (!!!values.born_date?.length) {
            setError('born_date', {
                type: 'custom',
                message: 'Wprowadź datę urodzenia użytkownika'
            });
          return false;
        }

        return true;
      }

    return (
        <section className={className}>
            <header>
                <h2 className="text-lg font-medium text-gray-900">
                    Informacje o Twoim profilu
                </h2>

                <p className="mt-1 text-sm text-gray-600">
                    Zaktualizuj informacje o profilu
                </p>
            </header>

            <form onSubmit={submit} className="mt-6 space-y-6">
                <div>
                    <InputLabel htmlFor="first_name" value="Imię" />

                    <TextInput
                        id="first_name"
                        className="mt-1 block w-full"
                        value={data.first_name}
                        onChange={(e) => setData("first_name", e.target.value)}
                        required
                        isFocused
                        autoComplete="first_name"
                    />

                    <InputError className="mt-2" message={errors.first_name} />
                </div>
                <div>
                    <InputLabel htmlFor="last_name" value="Nazwisko" />

                    <TextInput
                        id="last_name"
                        className="mt-1 block w-full"
                        value={data.last_name}
                        onChange={(e) => setData("last_name", e.target.value)}
                        required
                        isFocused
                        autoComplete="last_name"
                    />

                    <InputError className="mt-2" message={errors.first_name} />
                </div>

                {mustVerifyEmail && user.email_verified_at === null && (
                    <div>
                        <p className="text-sm mt-2 text-gray-800">
                            Twój adres nie jest zweryfikowany
                            <Link
                                href={route("verification.send")}
                                method="post"
                                as="button"
                                className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Kliknij tutaj aby ponownie wysłać link
                                weryfikacyjny.
                            </Link>
                        </p>

                        {status === "verification-link-sent" && (
                            <div className="mt-2 font-medium text-sm text-green-600">
                                Nowy link weryfikacyjny został wysłany
                            </div>
                        )}
                    </div>
                )}

                <div>
                    <InputLabel
                        htmlFor="born_date"
                        value="Data urodzenia"
                    />
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={dayjs(user.born_date ?? '1990-01-01')}
                            required
                            timezone="Europe/Warsaw"
                            openTo="year"
                            format="YYYY-MM-DD"
                            onChange={(value) => setData("born_date", value)}
                        />
                    </LocalizationProvider>
                    <InputError className="mt-2" message={errors.born_date} />
                </div>

                <div>
                    <InputLabel
                        htmlFor="screen_inch"
                        value="Rozmiar ekranu w calach"
                    />

                    <DecimalInput
                        id="screen_inch"
                        className="mt-1 block w-full"
                        value={data.screen_inch}
                        onChange={(e) => setData("screen_inch", e.target.value)}
                        required
                        isFocused
                        autoComplete="screen_inch"
                    />

                    <InputError className="mt-2" message={errors.screen_inch} />
                </div>

                <div className="flex items-center gap-4">
                    <PrimaryButton disabled={processing}>Zapisz</PrimaryButton>

                    <Transition
                        show={recentlySuccessful}
                        enter="transition ease-in-out"
                        enterFrom="opacity-0"
                        leave="transition ease-in-out"
                        leaveTo="opacity-0"
                    >
                        <p className="text-sm text-gray-600">Zapisano</p>
                    </Transition>
                </div>
            </form>
        </section>
    );
}
