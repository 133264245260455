
import star_1 from "%/icons/material-symbols-light-family-star-sharp.svg";
import star_2 from "%/icons/material-symbols-light-family-star-outline-sharp.svg";
import star_3 from "%/icons/ic-baseline-stars.svg";

export default class SparklingStar {

    constructor() {
        this.urls =  [
            star_1, star_2, star_3
        ];
        this.classes = ['star-1', 'star-2', 'star-3'];
    }

    createStar = (x, y) => {
        const star = document.createElement('img');
        const idx = Math.floor(Math.random() * this.classes.length);

        star.classList.add(this.classes[idx], 'star');
        star.setAttribute('src', this.urls[idx])
        star.setAttribute('style', `position: fixed; top: ${y}px; left: ${x}px`);
        star.animate([
            {
                transform: 'translate(0px, 0px)',
                opacity: 1,

            },
            {
                transform: `translate(${Math.random() * 600 - 300}px, ${Math.random() * 600 - 300}px)`,
                opacity: 1,

            }
        ], {
            duration: 600,
            iterations: 1,
            easing: 'ease-out'
        });
        setTimeout(() => star.remove(), 1500);
        return star;
    }
}